<template>
  <b-row>
    <b-col md="12">
      <!-- Filters -->
      <transition name="fade" mode="out-in">
        <b-card key="3" v-if="showFilters" no-body class="mb-2 ">
          <b-card-header class="d-flex justify-content-between pl-1 pt-1">
            <h5>
              {{ $t('Filters') }}
            </h5>
            <b-avatar @click="closeFilters()" v-b-tooltip.hover :title="titleTootipBtnCloseFilter" class="mr-1" button
                      variant="danger" size="30px">
              <feather-icon icon="XIcon"/>
            </b-avatar>
          </b-card-header>
          <b-card-body>
            <b-row class="mb-1" v-if="user.role.includes('TRCKOWN')">
              <b-col cols="12" md="12" class="mb-md-0 mb-2">
                <h5 class="font-weight-bold">{{ $t('Customer') }}</h5>
                <vue-autosuggest v-model="searchCustomer" :suggestions="filteredOptionsCustomer" :input-props="inputProps"
                                 @selected="selectHandlerCustomer" @input="onInputChangeCustomer">
                  <template slot-scope="{suggestion}">
                    <span
                        class="my-suggestion-item">{{ suggestion.item.name }}</span>
                  </template>
                </vue-autosuggest>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6" class="mb-md-0 mb-2">
                <h5 class="font-weight-bold">{{ $t('Date') }} <feather-icon v-b-tooltip.hover class="pull-up text-info" :title="titleTootipDateRange" icon="HelpCircleIcon" size="16"/> </h5>
                <flat-pickr  v-model="date" class="form-control" :config="flatpickrConfig"/>
              </b-col>
              <b-col cols="12" md="6" class="mb-md-0 mb-2">
                <h5 class="font-weight-bold">{{ $t('Asset') }}</h5>
                <vue-autosuggest v-model="searchAsset" :suggestions="filteredOptions" :input-props="inputProps"
                                 @selected="selectHandler" @input="onInputChange">
                  <template slot-scope="{suggestion}">
                    <span
                        class="my-suggestion-item">{{ suggestion.item.plate ? suggestion.item.plate : suggestion.item.chassisNumber }}</span>
                  </template>
                </vue-autosuggest>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="12">
                <b-button @click="fetchFailureReport()" :disabled="isLoading" variant="primary"
                          class="float-right btn-icon mt-md-2" :title="titleBtnSearch">
                  <feather-icon icon="SearchIcon"/>
                </b-button>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </transition>

      <!-- Table with result -->
      <b-card no-body class="invoice-preview-card" :class="transition">
        <table class="table ">
          <thead>
          <tr v-show="!showFilters">
            <th class="style-dark text-center" colspan="3" scope="col">

              <span style="font-size: 1rem;" class="font-weight-bold">
                  {{ $t('Filters') }}
              </span>
              <span v-show="!showFilters" class="float-right ml-1">
                  <b-avatar @click="showFilters = true" button rounded="sm" variant="primary"
                            size="25px" v-b-tooltip.hover :title="titleTootipBtnFilter">
                      <feather-icon icon="FilterIcon"/>
                  </b-avatar>
              </span>

            </th>
          </tr>
          </thead>
          <tr class="row px-1">
            <th class="col-sm-2 title-table-failure">{{ $t('Date') }}</th>
            <th class="col-sm-2 title-table-failure">{{ $t('Identifier') }}</th>
            <th class="col-sm-3 text-center title-table-failure">{{ $t('Failure Code') }}</th>
            <th class="col-sm-5 text-center title-table-failure">{{ $t('Description') }}</th>
          </tr>

          <tbody v-if="items.length > 0">
          <template v-for="item in items">
            <tr class="row px-1 ">
              <td class="d-flex justify-content-between col-sm-12 day-table"><span
                  class="font-weight-bold">{{ item.date }}</span> <span class="font-weight-bold">{{
                  item.dayWeek
                }}</span></td>
            </tr>
            
            
            
            <tr class="row px-1">
              <template v-for="hour in item.hours">
                <td class=" col-sm-2">{{ item.date + " às " + formateDate(hour.dateTime) }}</td>
                <td class=" col-sm-2">{{ hour.assetDescriptor }}</td>
                <td class=" col-sm-3 text-center">
                  <b-badge :variant="resolveVariant(hour.failureType)" class="badge-glow p-50" >
                    <strong style="font-size: larger"> {{ hour.failureCode }}</strong>
                  </b-badge>
                </td>
                <td class="col-sm-5 text-center">
                  <p class="mt-1 ">
                    <span>
                      {{ hour.failureDescription }}
                    </span>
                  </p>
                </td>
              </template>

            </tr>
          </template>

          </tbody>
          <tbody v-if="items.length == 0 && !isLoading">
          <tr class="row px-1 ">
            <td class="text-center col-sm-12 day-table">
              <h5>{{ this.$t('No matching records found') }}</h5>
            </td>
          </tr>
          </tbody>
          <tbody v-if="isLoading">
          <tr class="row px-1 ">
            <td class="text-center col-sm-12 day-table">
              <div>
                <h4>{{ this.$t('Preparing report') }}</h4>
                <b-spinner variant="primary" label="Report"/>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
        <!-- Section footer table -->
        <div v-show="items.length > 0" class="m-2">
          <b-row>

            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <span class="text-muted"> {{ $t('Showing') }} {{
                  from
                }} {{ $t('to') }} {{ to }} {{ $t('of') }} {{ totalItems }} {{ $t('entries') }} </span>
            </b-col>
            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

              <b-pagination v-model="currentPage" :total-rows="totalItems" per-page="10" first-number last-number
                            class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18"/>
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18"/>
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>

    </b-col>
  </b-row>
</template>

<script>

import {
  ref,
  onUnmounted
} from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
  VBTooltip,
  BFormCheckbox,
  BModal,
  BFormInput,
  BFormGroup,
  BTh,
  BTr,
  BPagination,
  BBadge,
  BAvatar,
  BFormSelect,
  BSpinner
} from 'bootstrap-vue'

import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'

import Multiselect from 'vue-multiselect'

import {
  VueAutosuggest
} from 'vue-autosuggest'
import assetStoreModule from "@/views/main/asset/assetStoreModule";
import queriesStoreModule from "@/views/main/queries/queriesStoreModule";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import customerStoreModule from "@/views/main/customer/customerStoreModule";

export default {
  directives: {

    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },

  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BFormCheckbox,
    BModal,
    BFormInput,
    BFormGroup,
    BTh,
    BTr,
    BBadge,
    BPagination,
    BAvatar,
    BFormSelect,
    BSpinner,
    VuePerfectScrollbar,
    flatPickr,
    vSelect,
    Multiselect,
    VueAutosuggest
  },

  data() {
    return {
      isLoading: false,
      transition: '',
      titleTootipBtnFilter: this.$t('Open Filters'),
      titleTootipBtnCloseFilter: this.$t('Close Filters'),
      titleBtnSearch: this.$t('Search'),
      titleTootipDateRange: this.$t('Maximum interval of 31 days.'),

      date: [new Date().setDate(new Date().getDate() - 7), new Date().setDate(new Date().getDate())],
      currentPage: 1,
      showFilters: false,
      flatpickrConfig: {
        mode: 'range',
        minDate: new Date().setDate(new Date().getDate() - 31),
        //defaultDate: ,
        maxDate: new Date(),
        altFormat: "d/m/Y",
        altInput: true,
        enableTime: false,
        
      },

      searchAsset: '',
      assetId: '',
      datasuggest: [],
      filteredOptions: [],
      inputProps: {
        id: 'autosuggest__input',
        class: 'form-control',
        placeholder: this.$t('Search'),
      },
      limit: 3,
      selected: null,

      filteredOptionsCustomer: [],
      searchCustomer: '',
      
      items:[],
      totalItems: 0,
      from: 0,
      to: 0,
    }
  },
  watch: {
    currentPage() {
      this.fetchFailureReport()
    }
  },
  mounted() {
    this.fetchFailureReport()
  },

  methods: {
    showSnackbar(config = {title: '', icon: '', variant: 'info'}) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: config.title,
          icon: config.icon,
          variant: config.variant,
        },
      })
    },
    
    dateFromString(string) {
      alert(string)
      const [date, month, year] = string.split("/")
      return new Date(year, month - 1, date)
    },
    
    formatDate(date) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');

      return `${day}/${month}/${year} ${hours}:${minutes}`;
    },
    
    formatDateRange(dateRange, type = "string") {
      
      // Early return for string with "to" and type "string"
      if (typeof dateRange === "string" && dateRange.includes("to") && type === "string") {
        return dateRange.split(" to ").map(this.formatDate);
      }

      const formattedDates = dateRange.map(date => (typeof date === "string" ? this.formatDate(date) : date));
      return formattedDates.map(date => this.dateFromString?.(date));
    },
    ensureAllowedDateRange(dates) {
      if(dates.length === 2){
        const milliSecondsDiff = dates[0] - dates[1];
        const diffDays = milliSecondsDiff / (1000 * 3600 * 24)
        if(diffDays > 31){
          this.showSnackbar({
             title: this.$t('Max interval one month'),
             icon: 'InfoIcon',
          })
          this.date = ''
        }
      }
    },
    
    fetchFailureReport() {
      this.items = []
      this.isLoading = true
      let dayStart = new Date(this.date[0])
      let dayEnd = new Date(this.date[1])
      if(typeof this.date === "string"){
        dayStart = this.date.split(" to ")[0]
        dayEnd = this.date.split(" to ")[1]
      }
      store
          .dispatch(`${this.QUERIES_APP_STORE_MODULE_NAME}/fetchFailureReport`, {
            page: this.currentPage,
            assetId: this.assetId,
            customerId: this.customerId,
            dayStart: dayStart,
            dayEnd: dayEnd ? dayEnd : dayStart

          })
          .then(response => {
            this.items = this.responseToItem(response.data.items)
            this.totalItems = response.data.totalItems

            const localItemsCount = this.items ? response.data.items.length : 0
            const perPage = 10
            this.from = perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0)
            this.to = perPage * (this.currentPage - 1) + localItemsCount

          })
          .catch((e) => {
            console.log('erro', e)
            this.items = []
          })
          .finally(f =>{
            this.isLoading = false
          })
    },

    responseToItem(alarms) {
      // this gives an object with dates as keys
      const groups = alarms.reduce((groups, game) => {
        const date = new Date(game.dateTime).toLocaleDateString("pt-BR");
        //const date = game.datetime.split('T')[0];
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(game);
        return groups;
      }, {});

      // Edit: to add it in the array format instead
      const groupArrays = Object.keys(groups).map((date) => {
        const dayWeek = this.getDayWeek(date)
        return {
          date,
          dayWeek,
          hours: groups[date]
        };
      });

      return groupArrays
    },

    getDayWeek(dt) {
      const month = dt.split('/')[1]
      const day = dt.split('/')[0]
      const year = dt.split('/')[2]

      const week = new Date(`${month}/${day}/${year}`).getDay()

      switch (week) {
        case 0:
          return this.$t('Sunday')
        case 1:
          return this.$t('Monday')
        case 2:
          return this.$t('Tuesday')
        case 3:
          return this.$t('Wednesday')
        case 4:
          return this.$t('Thursday')
        case 5:
          return this.$t('Friday')
        case 6:
          return this.$t('Saturday')
        default:
          // code block
      }
    },
    
    formateDate(date) {
      const newDate = new Date(date).toLocaleTimeString(navigator.language, {
        hour: '2-digit',
        minute: '2-digit'
      })
      //const dateFormated = `${newDate[0]}:${newDate[1]}`

      return newDate
    },
    
    resolveVariant(typeId) {
      switch (typeId) {
        case 1 : return 'info'
        case 2: return 'warning'
        case 3: return 'danger'
      }
      return 'info'
    },

    selectHandler(option) {
      this.selected = option.item
      this.filteredOptions = []
      this.searchAsset = option.item.plate ? option.item.plate : option.item.chassisNumber
      this.assetId = option.item.id
    },

    selectHandlerCustomer(option) {
      this.filteredOptionsCustomer = []
      this.searchCustomer = option.item.name
      this.customerId = option.item.id
    },

    onInputChange(text) {
      if (text === '' || text === undefined || text.length < 3) {
        this.assetId = ''
        return
      }

      store.dispatch(`${this.ASSET_APP_STORE_MODULE_NAME}/fetchAssets`, {
        q: this.searchAsset,
        page: 1,
        perPage: 10

      })
          .then(response => {

            this.filteredOptions = [{
              data: response.data.data,
            }]

          })
          .catch(error => {

            console.log(error)

          })
    },
    onInputChangeCustomer(text) {
      if (text === '' || text === undefined || text.length < 3) {
        this.customerId = ''
        return
      }

      store.dispatch(`${this.CUSTOMER_APP_STORE_MODULE_NAME}/fetchCustomers`, {
        q: this.searchCustomer,
        page: 1,
        perPage: 10

      })
          .then(response => {

            this.filteredOptionsCustomer = [{
              data: response.data.data,
            }]

          })
          .catch(error => {

            console.log(error)

          })
    },
    closeFilters() {
      this.showFilters = false

    },
    msgDialog(msg, icon) {
      this.$swal({
        position: 'top-end',
        icon: icon,
        title: msg,
        showConfirmButton: false,
        timer: 1500 * 3,
        customClass: {
          confirmButton: 'btn btn-primary',
        },

        buttonsStyling: false,
      })
    },
  },
  setup() {
    const ASSET_APP_STORE_MODULE_NAME = 'app-asset'
    const QUERIES_APP_STORE_MODULE_NAME = 'app-queries'
    const CUSTOMER_APP_STORE_MODULE_NAME = 'app-customer'

    if (!store.hasModule(QUERIES_APP_STORE_MODULE_NAME)) store.registerModule(QUERIES_APP_STORE_MODULE_NAME, queriesStoreModule)
    if (!store.hasModule(ASSET_APP_STORE_MODULE_NAME)) store.registerModule(ASSET_APP_STORE_MODULE_NAME, assetStoreModule)
    if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, customerStoreModule)

    
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(QUERIES_APP_STORE_MODULE_NAME)) store.unregisterModule(QUERIES_APP_STORE_MODULE_NAME)
      if (store.hasModule(ASSET_APP_STORE_MODULE_NAME)) store.unregisterModule(ASSET_APP_STORE_MODULE_NAME)
      if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME)
    })

    const user = localStorage.userData != undefined ? JSON.parse(localStorage.userData) : {}

    return {
      QUERIES_APP_STORE_MODULE_NAME,
      ASSET_APP_STORE_MODULE_NAME,
      CUSTOMER_APP_STORE_MODULE_NAME,
      user
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"/>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style scoped lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';



.alarm-section {
  //padding: 1.5rem;
  border-bottom: 1px solid #ebe9f1;
}

.rise {
  transition-duration: .8s;
  transform: translate(0px, -26.5%);
}

.event-weekendmove {
  background-color: #ea5455 !important;
}

.day-table {
  background-color: #ebe9f1;
}
.title-table-failure{
  background-color: #f5f4f9;
}

.style-dark {
  background-color: #d3d6dc !important;
}

.ps-customizer-area {
  height: calc(100% - 83px)
}

.per-page-selector {
  width: 90px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .8s ease;
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */

{
  opacity: 0;
  transition: opacity .8s ease;
}
</style>