<template>
<div>

    <!-- Filters 
    <customers-list-filters :role-filter.sync="roleFilter" :plan-filter.sync="planFilter" :status-filter.sync="statusFilter" :role-options="roleOptions" :plan-options="planOptions" :status-options="statusOptions" />-->

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">

        <div class="m-2">

            <!-- Table Top -->
            <b-row>

                <!-- Per Page -->
                <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                    <label>{{ $t('Show')}}</label>
                    <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
                    <label>{{ $t('entries')}}</label>
                </b-col>

                <!-- Search -->
                <b-col cols="12" md="6">
                    <div class="d-flex align-items-center justify-content-end">
                        <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
                        <b-button v-if="user.role == 'TRCKOWN' || user.role == 'SERVSHAR' || user.role == 'DEALER'" variant="primary" :to="{ name: 'register-customer' }">
                            <span class="text-nowrap">{{ $t('Add Customer')}}</span>
                        </b-button>
                    </div>
                </b-col>
            </b-row>

        </div>

        <b-table ref="refCustomerListTable" class="position-relative" :items="fetchCustomers" responsive :fields="tableColumns" primary-key="id" :sort-by.sync="sortBy" show-empty :empty-text="emptyText" :sort-desc.sync="isSortDirDesc">

            <!-- Column: customer -->
            <template #head(plate)>
                {{ $t('Customer')}}
            </template>
            <template #cell(name)="data">
                <b-media vertical-align="center">
                    <template #aside>
                        <b-avatar variant="light-primary" size="40" :to="{ name: 'apps-users-view', params: { id: data.item.id } }">
                            <feather-icon icon="UsersIcon" size="25" />
                        </b-avatar>
                    </template>
                    <b-link :to="{ name: 'register-customer', params: { id: data.item.id } }" class="font-weight-bold d-block text-nowrap">
                        {{ data.item.name }}
                    </b-link>
                    <small class="text-muted">{{ $t('Owner')}}: <strong>{{ data.item.ownerName }}</strong></small>
                </b-media>
            </template>

            <!-- Column: Address 
            <template #cell(address)="data">
                <div class="font-weight-bold d-block text-nowrap">
                    {{ data.item.address }}
                </div>
            </template>

             Column: Contact 
            <template #cell(contact)="data">
                <div class="font-weight-bold d-block text-nowrap">

                    {{ data.item.contactName ? data.item.contactName : data.item.fullName }}

                    <small class="text-muted d-block">Tel: <strong>{{ data.item.contact }}</strong> </small>
                    <small class="text-muted d-block">Email: <strong>{{ data.item.email }}</strong> </small>
                </div>
            </template>-->

            <!-- Column: Document -->
            <template #head(document)>
                {{ $t('Document')}}
            </template>
            <template #cell(document)="data">
                <div class="font-weight-bold d-block text-nowrap">
                    {{ data.item.document }}
                </div>
            </template>

            <!-- Column: Email -->
            <template #head(email)>
                {{ $t('Email')}}
            </template>
            <template #cell(email)="data">
                <div class="font-weight-bold d-block text-nowrap">
                    {{ data.item.email }}
                </div>
            </template>

            <!-- Column: manager -->
            <template #head(manager)>
                {{ $t('Manager')}}
            </template>
            <template #cell(manager)="data">
                <div class="font-weight-bold d-block text-nowrap">
                    {{ data.item.manager }}
                </div>
            </template>

            <!-- Column: managerEmail -->
            <template #head(managerEmail)>
                {{ $t('Manager Email')}}
            </template>
            <template #cell(managerEmail)="data">
                <div class="font-weight-bold d-block text-nowrap">
                    {{ data.item.managerEmail }}
                </div>
            </template>

            <!-- Column: managerPhone -->
            <template #head(managerPhone)>
                {{ $t('Manager Phone')}}
            </template>
            <template #cell(managerPhone)="data">
                <div class="font-weight-bold d-block text-nowrap">
                    {{ data.item.managerPhone }}
                </div>
            </template>

            <!-- Column: Actions -->
            <template #head(actions)>
                {{ $t('Actions')}}
            </template>
            <template #cell(actions)="data">
                <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

                    <template #button-content>
                        <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                    </template>

                    <b-dropdown-item v-if="user.role == 'TRCKOWN' || user.role == 'SERVSHAR' || user.role == 'DEALER'" :to="{ name: 'register-customer', params: { id: data.item.id } }">
                        <feather-icon icon="EditIcon" />
                        <span class="align-middle ml-50">{{ $t('Edit')}}</span>
                    </b-dropdown-item>

                    <b-dropdown-item @click="modalDelete(data.item.id)" v-if="user.role == 'TRCKOWN' || user.role == 'SERVSHAR' || user.role == 'DEALER'">
                        <feather-icon icon="Trash2Icon" />
                        <span class="align-middle ml-50">{{ $t('Delete')}}</span>
                    </b-dropdown-item>
                </b-dropdown>
            </template>

        </b-table>
        <div class="mx-2 mb-2">
            <b-row>

                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                    <span class="text-muted"> {{ $t('Showing')}} {{ dataMeta.from }} {{ $t('to')}} {{ dataMeta.to }} {{ $t('of')}} {{ dataMeta.of }}{{ $t('entries')}} </span>
                </b-col>
                <!-- Pagination -->
                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

                    <b-pagination v-model="currentPage" :total-rows="totalCustomers" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>

                </b-col>

            </b-row>
        </div>
    </b-card>
</div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {
    ref,
    onUnmounted
} from '@vue/composition-api'
import {
    avatarText
} from '@core/utils/filter'
import CustomersListFilters from './components/CustomersListFilters.vue'
import useCustomersList from './useCustomersList'
import customerStoreModule from './customerStoreModule'

export default {
    components: {
        CustomersListFilters,

        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,

        vSelect,
    },
    data(){
        return {
            emptyText: this.$t('No matching records found')
        }
    },
    methods: {
        modalDelete(myId) {
            this.$swal({
                title: this.$t('Are you sure?'),
                text: this.$t("You can't revert your action"),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: this.$t('Yes'),
                cancelButtonText: this.$t('No'),
                showCloseButton: true,
                showLoaderOnConfirm: true,
                customClass: {
                    cancelButton: 'cancelBtn',
                    confirmButton: 'mr-1',
                }
            }).then((result) => {
                if (result.value) {
                    console.log('ID', myId)
                    this.deleteCustomer(myId)

                } else {}
            })
        },

        deleteCustomer(myId) {
            store.dispatch('app-customer/deleteCustomer', {
                    id: myId
                })
                .then(response => {
                    this.$swal(this.$t("Deleted"), `${this.$t("You successfully deleted")}`, 'success')
                    .then((result) => {
                        if (result.value) {
                            this.refetchData()

                        } else {}
                    })
                })
                .catch(error => {
                    this.$swal(this.$t('Error'), this.$t('An error occurred while trying to delete'), 'error')
                    console.log(error);
                })
        }
    },
    setup() {
        const CUSTOMER_APP_STORE_MODULE_NAME = 'app-customer'

        // Register module
        if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, customerStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME)
        })

        const isAddNewCustomerSidebarActive = ref(false)

        const user = localStorage.userData != undefined ? JSON.parse(localStorage.userData) : {}

        const roleOptions = [{
                label: 'Admin',
                value: 'admin'
            },
            {
                label: 'Author',
                value: 'author'
            },
            {
                label: 'Editor',
                value: 'editor'
            },
            {
                label: 'Maintainer',
                value: 'maintainer'
            },
            {
                label: 'Subscriber',
                value: 'subscriber'
            },
        ]

        const planOptions = [{
                label: 'Basic',
                value: 'basic'
            },
            {
                label: 'Company',
                value: 'company'
            },
            {
                label: 'Enterprise',
                value: 'enterprise'
            },
            {
                label: 'Team',
                value: 'team'
            },
        ]

        const statusOptions = [{
                label: 'Pending',
                value: 'pending'
            },
            {
                label: 'Active',
                value: 'active'
            },
            {
                label: 'Inactive',
                value: 'inactive'
            },
        ]

        const {
            fetchCustomers,
            tableColumns,
            perPage,
            currentPage,
            totalCustomers,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refCustomerListTable,
            refetchData,

            // UI
            resolveCustomerRoleVariant,
            resolveCustomerRoleIcon,
            resolveCustomerStatusVariant,

            // Extra Filters
            //roleFilter,
            //planFilter,
            //statusFilter,
        } = useCustomersList()

        return {

            // Sidebar

            fetchCustomers,
            tableColumns,
            perPage,
            currentPage,
            totalCustomers,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refCustomerListTable,
            refetchData,

            // Filter
            avatarText,

            // UI
            resolveCustomerRoleVariant,
            resolveCustomerRoleIcon,
            resolveCustomerStatusVariant,

            roleOptions,
            planOptions,
            statusOptions,
            user

            // Extra Filters
            //roleFilter,
            //planFilter,
            //statusFilter,
        }
    },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style><style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
