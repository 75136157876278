<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-row class="mb-50 ml-50">
            <b-col cols="12" md="5" lg="4" class="mb-md-0 mb-3">
              <h5 class="font-weight-bold">{{ $t('Choose the Date') }}</h5>

              <div class="d-flex">
                <flat-pickr id="date-range-picker" v-model="dateRange" :config="flatpickrConfig"
                            @on-change="ensureAllowedDateRange" class="form-control flat-picker"/>
                <b-button @click="updateReport" size="sm" variant="primary"
                          style="border-radius: 0 0.357rem 0.357rem 0;">
                  <feather-icon
                      icon="ArrowRightIcon"
                      size="16"
                  />
                </b-button>
              </div>
            </b-col>
            <b-col cols="12" md="5" class="mb-md-0 mb-2">
              <h5 class="font-weight-bold">{{ $t('Customer') }}</h5>
              <div class="d-flex">
                <vue-autosuggest v-model="searchCustomer" :suggestions="filteredOptions" :input-props="inputProps"
                                 @selected="selectHandler" @input="onInputChange" class="flex-fill">
                  <template slot-scope="{suggestion}">
                    <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
                    <div v-show="suggestion.item.ownerName">
                      <span class="text-muted">{{ $t('Belongs to: ') }}</span>
                      <span class="font-weight-bold"> {{ suggestion.item.ownerName }} </span>
                    </div>
                  </template>
                </vue-autosuggest>

                <b-button :disabled="searchCustomer.length === 0" @click="clearInput" size="sm"
                          style="border-radius: 0 0.357rem 0.357rem 0;">
                  <feather-icon
                      icon="XIcon"
                      size="16"
                  />
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col cols="12" id="consumption">
        <apex-data-bar-chart :axis-x="consumptionReportAxisX" :axis-y="consumptionReportAxisY" :is-loading="consumptionReportIsLoading"
                             title="Ativos com maior consumo (L/h)" chart-color="#1E9D64" measurement="Litros por Hora"/>
      </b-col>
      <b-col cols="12" id="severeUsage">
        <apex-data-bar-chart :axis-x="severeUsageReportAxisX" :axis-y="severeUsageReportAxisY" :is-loading="severeUsageReportIsLoading"
        title="Ativos com maior tempo em carga alta" chart-color="#ff9f43" measurement="Minutos"/>
      </b-col>
      <b-col cols="12" id="idleUsage">
        <apex-data-bar-chart :axis-x="idleUsageReportAxisX" :axis-y="idleUsageReportAxisY" :is-loading="idleUsageReportIsLoading"
                             title="Ativos com maior tempo em uso ocioso" chart-color="#00cfe8" measurement="Minutos"/>
      </b-col>
      <b-col cols="12" id="countFails">
        <apex-data-bar-chart :axis-x="failUsageReportAxisX" :axis-y="failUsageReportAxisY" :is-loading="failUsageReportIsLoading"
                             title="Maior número de falhas" chart-color="#ea5455" measurement="Quantidade de Falhas"/>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import {BRow, BCol, BLink, BButton, BCard} from 'bootstrap-vue'

import flatPickr from 'vue-flatpickr-component'
import {VueAutosuggest} from "vue-autosuggest"

import ApexDataBarChart from './ApexDataBarChart.vue'
import IdleReport from './IdleReport.vue'
import CountFailsCode from './CountFailsCodeReport.vue'
import ApexLineAreaChart from './ApexLineAreaChart.vue'
import store from "@/store";
import {onUnmounted, ref} from "@vue/composition-api";
import customerStoreModule from "@/views/main/customer/customerStoreModule";
import dashboardStoreModule from "@/views/main/dashboards/dashboardStoreModule";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BLink,
    BCard,
    flatPickr,
    VueAutosuggest,

    IdleReport,
    ApexDataBarChart,
    ApexLineAreaChart,
    CountFailsCode
    //CountFailsCode
  },
  data() {
    return {

      customerId: '',
      flatpickrConfig: {
        maxDate: new Date(),
        mode: 'range',
        dateFormat: "d/m/Y",
      },
      searchCustomer: '',
      datasuggest: [],
      filteredOptions: [],
      isExporting: false,
      inputProps: {
        id: 'autosuggest__input',
        class: 'form-control auto-suggestions-box',
        placeholder: "Cliente",
      },

      severeUsageReportAxisX: [],
      severeUsageReportAxisY: [],
      severeUsageReportIsLoading: false,

      idleUsageReportAxisX: [],
      idleUsageReportAxisY: [],
      idleUsageReportIsLoading: false,

      failUsageReportAxisX: [],
      failUsageReportAxisY: [],
      failUsageReportIsLoading: false,

      consumptionReportAxisX: [],
      consumptionReportAxisY: [],
      consumptionReportIsLoading: false
    }
  },
  mounted() {
    this.updateReport()
  },
  watch: {

    customerId:{
      handler(newVal) {
        this.updateReport()
      }
    }
  },
  methods: {
    updateReport() {
      this.fetchSevere()
      this.fetchIdle()
      this.fetchFaultCounter()
      this.fetchConsumption()
    },
    formatDate(date) {
      const month = String(date.getMonth() + 1)
      const formattedMonth = month.length === 1 ? '0' + month : month

      return `${date.getDate()}/${formattedMonth}/${date.getFullYear()}`;
    },
    ensureAllowedDateRange(dates) {
      const formattedDates = this.formatDateRange(dates, "date")

      if (formattedDates.length > 1) {
        const minAllowedDate = new Date(formattedDates[1])
        minAllowedDate.setDate(minAllowedDate.getDate() - 31)

        if (formattedDates[0] < minAllowedDate) {
          this.showSnackbar({
            title: this.$t('Max interval one month'),
            icon: 'InfoIcon',
          })

          this.dateRange = [this.formatDate(minAllowedDate), this.formatDate(formattedDates[1])].join(" to ")
        }
      }
    },
    formatDateRange(dateRange, type = "string") {
      const stringValues = typeof dateRange === "string"
          ? dateRange.split(" to ")
          : dateRange.map(date => this.formatDate(date))

      if (type === "string") return stringValues
      return stringValues.map(this.dateFromString)
    },
    selectHandler(option) {
      this.selected = option.item
      this.filteredOptions = []
      this.searchCustomer = option.item.name
      this.customerId = option.item.id
    },
    onInputChange(text) {
      if (text === '' || text === undefined || text.length < 3) {
        this.customerId = ''
        return
      }

      store.dispatch('app-customer/fetchCustomers', {
        q: this.searchCustomer,
        perPage: 5,
        page: 1,
      }).then(response => {
        this.filteredOptions = [{
          data: response.data.data,
        }]
      }).catch(console.log)
    },
    clearInput() {
      this.searchCustomer = ''
      this.onInputChange('')
    },
    fetchSevere() {
     
      this.severeUsageReportIsLoading = true

      const dates = typeof this.dateRange === "string"
          ? this.dateRange.split(" to ")
          : this.dateRange.map(date => this.formatDate(date))

      store.dispatch('app-control-fleet/fetchSevere', {
        start: dates[0],
        end: dates[1],
        customerId: this.customerId
      })
        .then((response) => {
          const totalMinutesArray = []
          const identifierArray = []
          if (response && response.data && response.data.items) {
            response.data.items.forEach(item => {
              totalMinutesArray.push(item.totalMinutes)
              identifierArray.push(item.assetDescriptor)
            })
          }
          this.severeUsageReportAxisY = totalMinutesArray
          this.severeUsageReportAxisX = identifierArray
        })
        .catch(error => {
          this.isLoading = false
          console.log(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Erro ao gerar relatório",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(f => {
          this.severeUsageReportIsLoading = false
        })  
    },
    fetchIdle() {

      this.idleUsageReportIsLoading = true

      const dates = typeof this.dateRange === "string"
          ? this.dateRange.split(" to ")
          : this.dateRange.map(date => this.formatDate(date))

      store.dispatch('app-control-fleet/fetchIdle', {
        start: dates[0],
        end: dates[1],
        customerId: this.customerId
      })
          .then((response) => {
            const totalMinutesArray = []
            const identifierArray = []
            if (response && response.data && response.data.items) {
              response.data.items.forEach(item => {
                totalMinutesArray.push(item.totalMinutes)
                identifierArray.push(item.assetDescriptor)
              })
            }
            this.idleUsageReportAxisY = totalMinutesArray
            this.idleUsageReportAxisX = identifierArray
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Erro ao gerar relatório",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
          .finally(f => {
            this.idleUsageReportIsLoading = false
          })
    },
    fetchFaultCounter() {

      this.failUsageReportIsLoading = true

      const dates = typeof this.dateRange === "string"
          ? this.dateRange.split(" to ")
          : this.dateRange.map(date => this.formatDate(date))

      store.dispatch('app-control-fleet/fetchFailureCount', {
        start: dates[0],
        end: dates[1],
        customerId: this.customerId
      })
          .then((response) => {
            const totalMinutesArray = []
            const identifierArray = []
            if (response && response.data && response.data.items) {
              response.data.items.forEach(item => {
                totalMinutesArray.push(item.totalFailures)
                identifierArray.push(item.assetDescriptor)
              })
            }
            this.failUsageReportAxisY = totalMinutesArray
            this.failUsageReportAxisX = identifierArray
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Erro ao gerar relatório",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
          .finally(f => {
            this.failUsageReportIsLoading = false
          })
    },
    fetchConsumption() {

      this.consumptionReportIsLoading = true

      const dates = typeof this.dateRange === "string"
          ? this.dateRange.split(" to ")
          : this.dateRange.map(date => this.formatDate(date))

      store.dispatch('app-control-fleet/fetchConsumption', {
        start: dates[0],
        end: dates[1],
        customerId: this.customerId
      })
          .then((response) => {
            const averageArray = []
            const identifierArray = []
            if (response && response.data && response.data.items) {
              response.data.items.forEach((item,i) => {
                averageArray.push(parseFloat(item.average.toFixed(2)))
                identifierArray.push(item.assetDescriptor)
              })
            }
            this.consumptionReportAxisX = identifierArray
            this.consumptionReportAxisY = averageArray
          })
          .catch(error => {
            this.isLoading = false
            console.log(error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Erro ao gerar relatório",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
          .finally(f => {
            this.consumptionReportIsLoading = false
          })
    },
    
  },
  
  setup() {

    const today = new Date();
    // First day of the current month
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    const dateRange = ref([firstDay, new Date()])

    const CUSTOMER_APP_STORE_MODULE_NAME = 'app-customer'
    const CONTROL_FLEET_APP_STORE_MODULE_NAME = 'app-control-fleet'

    if (!store.hasModule(CONTROL_FLEET_APP_STORE_MODULE_NAME)) store.registerModule(CONTROL_FLEET_APP_STORE_MODULE_NAME, dashboardStoreModule)
    if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, customerStoreModule)

    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME)
      if (store.hasModule(CONTROL_FLEET_APP_STORE_MODULE_NAME)) store.unregisterModule(CONTROL_FLEET_APP_STORE_MODULE_NAME)
    })

    return {
      dateRange
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';

.auto-suggestions-box, .flat-picker {
  border-radius: 0.357rem 0 0 0.357rem;
}
</style>