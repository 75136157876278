import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchManufacturers(ctx, queryParams) {
            const {query, page, perPage} = queryParams;
            return new Promise((resolve, reject) => {
                axios
                    .get(`/api/v1/Asset/searchmanufacturer?Search=${query}&Page=${page}&PerPage=${perPage}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchManufacturer(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/api/v1/asset/getassetmanufacturer?id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addManufacturer(ctx, manufacturerData) {
            return new Promise((resolve, reject) => {

                let manufacturer = manufacturerData.manufacturerData
                axios
                    .post('/api/v1/Asset/createassetmanufacturer', manufacturer)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        updateManufacturer(ctx, manufacturerData) {
            return new Promise((resolve, reject) => {

                let manufacturer = manufacturerData.manufacturerData
                axios
                    .put('/api/v1/Asset/updateassetmanufacturer', manufacturer)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}