<template>
  <section class="invoice-preview-wrapper">
    <b-row>
      <b-col cols="12" md="7" xl="8">

        <b-card>

          <validation-observer ref="simpleRules">
            <b-form>
              <!-- Section Informations -->
              <h4>{{ $t('Informations') }}</h4>
              <hr style="margin-bottom: 16px;">
              <b-row>
                <!-- Field:  Name -->
                <b-col cols="12" md="4">
                  <b-form-group :label="$t('Name')" label-for="name">
                    <validation-provider #default="{ errors }" name="name" rules="required">
                      <b-form-input id="name" v-model="groupData.name"/>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Field:  Name -->
                <b-col cols="12" md="8">
                  <b-form-group :label="$t('Descripition')" label-for="Descripition">
                    <validation-provider #default="{ errors }" name="Descripition" rules="">
                      <b-form-input id="name" v-model="groupData.description"/>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

              </b-row>
              <b-row>
                <b-col cols="12" md="12">
                  <b-button class="float-right" variant="primary" type="submit" @click.prevent="validationForm">
                    {{ $t('Save') }}
                  </b-button>
                </b-col>
              </b-row>
              <hr v-show="groupData.id"/>

              <b-row v-if="groupData.id" class="mt-3 mb-2">
                <b-col cols="12" md="12">
                  <div class="d-flex align-items-center justify-content-start">
                    <h4 class="d-inline-block mr-1">{{ $t("Assets") }}</h4>
                    <!-- <b-avatar class="mr-2 mb-0" @click="openModalBind" button rounded="sm" variant="primary" size="24px" v-b-tooltip.hover title="Adicionar">
                        <feather-icon icon="PlusCircleIcon" />
                    </b-avatar> -->
                    <b-button class="mr-2 mb-0" @click="openModalBind" variant="primary" size="sm">
                      {{ $t('Add Asset') }}
                    </b-button>
                  </div>
                </b-col>
              </b-row>


              <template v-if="groupData.id && totalAssets > 0">
                <div>
                  <div class="table-responsive">
                    <table class="table table-striped">
                      <thead>
                      <tr>
                        <th scope="col">{{ $t('Identifier') }}</th>
                        <th scope="col">{{ $t('Nickname') }}</th>
                        <th scope="col">{{ $t('Manufacturer') }}</th>
                        <th scope="col">{{ $t('Model') }}</th>
                        <th scope="col">{{ $t('Actions') }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="asset in assets">
                        <td>{{ asset.plate || asset.chassisNumber }}</td>
                        <td>{{ asset.nickname }}</td>
                        <td>{{ asset.manufacturerName }}</td>
                        <td>{{ asset.modelName }}</td>
                        <td>
                          <div>
                            <b-avatar @click="modalDelete(asset.assetId, asset.bindId)" button rounded="sm"
                                      variant="danger" size="24px" v-b-tooltip.hover title="Remover">
                              <feather-icon icon="Trash2Icon"/>
                            </b-avatar>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="py-1 " style="background-color: #f3f2f7;">
                    <b-row>
                      <!-- Pagination -->
                      <b-col cols="12" sm="12"
                             class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="totalAssets" :per-page="perPage" first-number
                                      last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item"
                                      next-class="next-item">
                          <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18"/>
                          </template>
                          <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18"/>
                          </template>
                        </b-pagination>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </template>
            </b-form>
          </validation-observer>
          
        </b-card>
        

      </b-col>
      <!-- Right Col: (Alarm Settings) -->
      <b-col cols="12" md="5" xl="4" class="invoice-actions">
        <b-card>

          <!-- Header -->
          <div class="pb-1 customizer-section d-flex justify-content-between align-items-center alarm-section">
            <div>
              <h4 class="text-uppercase mb-1">
                {{$t('Alarms Settings')}}
              </h4>
              <small>{{$t('In this panel you can activate and deactivate different types of alarms for ')}}<strong>{{$t('the asset group')}}.</strong></small>
            </div>
          </div>
          <!-- Header -->

          <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="ps-customizer-area scroll-area mb-2">
            <!-- Move -->
            <div class="d-flex justify-content-between align-items-center mt-2">
                        <span>
                            <feather-icon v-b-tooltip.hover class="pull-up text-info" :title="titleTootipMove" icon="HelpCircleIcon" size="16" /> {{ $t('Move')}}
                        </span>
              <b-form-checkbox id="check-move" v-model="alarmMove.val" @change="toogleAlarm(MOVE_ALARM_NAME)" name="alarm-move" class="mr-0" switch inline />
            </div>

            <!-- Weekend Move -->
            <div class="d-flex justify-content-between align-items-center mt-2">
                        <span>
                            <feather-icon v-b-tooltip.hover class="pull-up text-info" :title="titleTootipWeekendMove" icon="HelpCircleIcon" size="16" /> {{ $t('Weekend Move')}}
                        </span>
              <b-form-checkbox id="check-weekend-move" v-model="alarmWeekendMove.val" @change="toogleAlarm(WEEKEND_MOVE_ALARM_NAME)" name="alarm-weekendmove" class="mr-0" switch inline />
            </div>

            <!-- Ignition -->
            <div class="d-flex justify-content-between align-items-center mt-2">
                        <span>
                            <feather-icon v-b-tooltip.hover class="pull-up text-info" :title="titleTootipIgnition" icon="HelpCircleIcon" size="16" /> {{ $t('Ignition')}}
                        </span>
              <b-form-checkbox id="check-ignition" @change="toogleAlarm(IGNITION_ALARM_NAME)" v-model="alarmIgnition.val" name="alarm-ignition" class="mr-0" switch inline />
            </div>

            <!-- Severe Use -->
            <div class="d-flex justify-content-between align-items-center mt-2">
                        <span>
                            <feather-icon v-b-tooltip.hover class="pull-up text-info" :title="titleTootipSevereUse" icon="HelpCircleIcon" size="16" /> {{ $t('Severe Use')}}
                        </span>
              <b-form-checkbox id="check-severe-use" v-model="alarmSevere.val" @change="toogleAlarm(SEVERE_USE_ALARM_NAME)" name="alarm-severeuse" class="mr-0" switch inline />
            </div>

            <!-- Iddle Use -->
            <div class="d-flex justify-content-between align-items-center mt-2 mb-2">
                        <span>
                            <feather-icon v-b-tooltip.hover class="pull-up text-info" :title="titleTootipIddleUse" icon="HelpCircleIcon" size="16" /> {{ $t('Iddle Use')}}
                        </span>
              <b-form-checkbox id="check-iddle-use" v-model="alarmIddle.val" @change="toogleAlarm(IDDLE_USE_ALARM_NAME)" name="alarm-iddleuse" class="mr-0" switch inline />
            </div>

<!--            &lt;!&ndash; Long Time without connection &ndash;&gt;-->
<!--            <div class="d-flex justify-content-between align-items-center mt-2 mb-2">-->
<!--                        <span>-->
<!--                            <feather-icon v-b-tooltip.hover class="pull-up text-info" :title="titleTootipLongTime" icon="HelpCircleIcon" size="16" /> {{ $t('Long Time Without Connection')}}:-->
<!--                            <strong v-if="alarmLongTime.longTime > 0 && alarmLongTime.val">{{ alarmLongTime.longTime }} h</strong>-->
<!--                        </span>-->
<!--              <b-form-checkbox id="check-over-speed" v-model="alarmLongTime.val" @change="toogleAlarm(LONG_TIME_ALARM_NAME)" name="alarm-long-time" class="mr-0" switch inline />-->
<!--            </div>-->

            <!-- Over Speed -->
            <div class="d-flex justify-content-between align-items-center mt-2 mb-2">
                        <span>
                            <feather-icon v-b-tooltip.hover class="pull-up text-info" :title="titleTootipOverSpeed" icon="HelpCircleIcon" size="16" /> {{ $t('Over Speed')}}:
                            <strong v-if="alarmOverSpeed.speed > 0 && alarmOverSpeed.val">{{ alarmOverSpeed.speed }} Km/h</strong>
                        </span>
              <b-form-checkbox id="check-over-speed" v-model="alarmOverSpeed.val" @change="toogleAlarm(OVER_SPEED_ALARM_NAME)" name="alarm-overspeed" class="mr-0" switch inline />
            </div>

            <!-- High Fuel Consumption -->
            <div class="d-flex justify-content-between align-items-center mt-2 mb-2">
                        <span>
                            <feather-icon v-b-tooltip.hover class="pull-up text-info" :title="titleTootipHighFuelConsumption" icon="HelpCircleIcon" size="16" /> {{ $t('High Fuel Consumption')}}:
                            <strong v-if="alarmHighFuelConsumption.consumption > 0 && alarmHighFuelConsumption.val">{{ alarmHighFuelConsumption.consumption }} L/h</strong>
                        </span>
              <b-form-checkbox id="check-high-fuel-consumption" v-model="alarmHighFuelConsumption.val" @change="toogleAlarm(HIGH_FUEL_CONSUMPTION_ALARM_NAME)" name="alarm-highfuelconsumption" class="mr-0" switch inline />
            </div>

            <!-- Low Fuel -->
            <div class="d-flex justify-content-between align-items-center mt-2 mb-2">
                        <span>
                            <feather-icon v-b-tooltip.hover class="pull-up text-info" :title="titleTootipLowFuel" icon="HelpCircleIcon" size="16" /> {{ $t('Low Fuel')}}:
                            <strong v-if="alarmLowFuel.fuel > 0 && alarmLowFuel.val">{{ alarmLowFuel.fuel }} %</strong>
                        </span>
              <b-form-checkbox id="check-low-fuel" v-model=" alarmLowFuel.val" @change="toogleAlarm(LOW_FUEL_ALARM_NAME)" name="alarm-lowfuel" class="mr-0" switch inline />
            </div>

          </vue-perfect-scrollbar>

        </b-card>
      </b-col>

    </b-row>
    <!-- Section Modals for create alarms -->
    <!-- Modal add asset on group -->
    <b-modal v-model="showModal" no-close-on-backdrop id="modal-cadastro" size="md" title="Cadastro"
             title-class="font-18" hide-footer @hide="limparFormCadastro">
      <div class="row ">
        <div class="col-md-12">
          <p class="sub-header font-weight-bold">{{ $t('Choose the asset to add to the group') }}</p>
        </div>
      </div>
      <form>
        <div class="row">
          <b-col cols="12" md="12">
            <b-form-group :label="$t('Asset')" label-for="asset">
              <vue-autosuggest v-model="asset.name" :suggestions="filteredOptions" :input-props="inputProps"
                               @selected="selectHandler" @input="onInputChange">
                <template slot-scope="{suggestion}">
                        <span
                            class="my-suggestion-item">{{
                            suggestion.item.plate ? suggestion.item.plate : suggestion.item.chassisNumber
                          }}</span>
                </template>
              </vue-autosuggest>
            </b-form-group>
          </b-col>
        </div>
        <div class="form-group text-right mt-1">
          <button class="btn btn-primary" type="button" @click="bindGroup()">{{ $t('Add') }}</button>
        </div>

      </form>
    </b-modal>

    
    <!--Modal Create Over Speed-->
    <b-modal ref="modal-create-over-speed" size="sm" centered hide-header hide-footer no-close-on-esc no-close-on-backdrop hide-header-close>
      <div class="mx-3">
        <p class="h5 mt-2 text-center font-weight-bold">{{ $t('Enter the maximum speed in km/h') }}</p>
        <b-form-input placeholder="Km/h" v-model="maxSpeed" />
        <div class="d-flex justify-content-between align-items-center mt-1 ">
          <b-button variant="danger" @click="cancelOverSpeed()">Cancelar</b-button>
          <b-button variant="success" @click="validateOverSpeed()">Confirmar</b-button>
        </div>
      </div>
    </b-modal>

    <!--Modal Create High Fuel Consumption-->
    <b-modal ref="modal-create-high-fuel-consumption" size="sm" centered hide-header hide-footer no-close-on-esc no-close-on-backdrop hide-header-close>
      <div class="mx-3">
        <p class="h5 mt-2 text-center font-weight-bold">{{ $t('Enter consumption in L/h') }}</p>
        <b-form-input placeholder="L/h" v-model="consumption" />
        <div class="d-flex justify-content-between align-items-center mt-1 ">
          <b-button variant="danger" @click="cancelHighFuelConsumption()">Cancelar</b-button>
          <b-button variant="success" @click="validateHighFuelConsumption()">Confirmar</b-button>
        </div>
      </div>
    </b-modal>

    <!--Modal Create Low Fuel-->
    <b-modal ref="modal-create-low-fuel" size="sm" centered hide-header hide-footer no-close-on-esc no-close-on-backdrop hide-header-close>
      <div class="mx-3">
        <p class="h5 mt-2 text-center font-weight-bold">{{ $t('Enter the amount in %') }}</p>
        <b-form-input placeholder="%" v-model="fuel" />
        <div class="d-flex justify-content-between align-items-center mt-1 ">
          <b-button variant="danger" @click="cancelLowFuel()">Cancelar</b-button>
          <b-button variant="success" @click="validateLowFuel()">Confirmar</b-button>
        </div>
      </div>
    </b-modal>

    <!--Modal Create Long Time -->
    <b-modal ref="modal-create-long-time" size="sm" centered hide-header hide-footer no-close-on-esc no-close-on-backdrop hide-header-close>
      <div class="mx-3">
        <p class="h5 mt-2 text-center font-weight-bold">{{ $t('Enter the amount hours') }}</p>
        <b-form-input :placeholder="$t('Hours')" v-model="longTime" />
        <div class="d-flex justify-content-between align-items-center mt-1 ">
          <b-button variant="danger" @click="cancelLongTime()">Cancelar</b-button>
          <b-button variant="success" @click="validateLongTime()">Confirmar</b-button>
        </div>
      </div>
    </b-modal>
  </section>


</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink, BFormCheckbox, BModal, VBToggle, VBTooltip,
} from 'bootstrap-vue'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BFormTextarea,
  BNavItemDropdown,
  BDropdownItem,
  BImg,
  BAvatar,
  BPagination
} from 'bootstrap-vue'
import {
  ref,
  onUnmounted
} from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import managementStoreModule from '../managementStoreModule'

import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.br'

import vSelect from 'vue-select'
import {
  VueAutosuggest
} from 'vue-autosuggest'

import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate'
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  max,
  digits,
  alphaDash,
  length,
} from '@validations'
import alarmsStoreModule from "@/views/main/alarms/alarmsStoreModule";

export default {
  directives: {

    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  components: {
    BModal,
    BFormCheckbox,
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCardText,
    BFormTextarea,
    BNavItemDropdown,
    BDropdownItem,
    BImg,
    BAvatar,
    BPagination,

    Cleave,
    vSelect,
    VueAutosuggest,
    flatPickr

  },
  data() {
    return {
      myId: router.currentRoute.params.id,
      required,
      email,
      confirmed,
      url,
      between,
      alpha,
      integer,
      password,
      min,
      digits,
      alphaDash,
      length,
      max,


      datasuggest: [],
      filteredOptions: [],
      inputProps: {
        id: 'autosuggest__input',
        class: 'form-control',
        placeholder: "Search for your asset ...",
      },
      limit: 5,
      selected: null,

      groupData: {
        id: '',
        name: '',
        description: ''
      },

      assets: [],

      totalAssets: 0,
      currentPage: 1,
      perPage: 10,

      asset: {
        name: '',
        id: ''
      },
      showModal: false,

      //Title Tooltips form alarms titleTootipBtnCloseFilter
      titleTootipMove: this.$t('Enable to be notified when the vehicle starts moving'),
      titleTootipWeekendMove: this.$t('Enable to be notified when the vehicle starts moving on weekends only'),
      titleTootipIgnition: this.$t('Enable to be notified when vehicle is on'),
      titleTootipSevereUse: this.$t('Enable to be notified when vehicle is in severe use'),
      titleTootipIddleUse: this.$t('Enable to be notified when vehicle is in idle use'),
      titleTootipOverSpeed: this.$t('Enable to be warned when the vehicle exceeds the chosen speed'),
      titleTootipHighFuelConsumption: this.$t('Enable to be notified when the vehicle exceeds the chosen consumption'),
      titleTootipLowFuel: this.$t('Enable to be notified when the vehicle is below the informed limit'),
      titleTootipLongTime: this.$t('Enable to be notified when the asset has not communicated for a long time'),

      //Variables Alarms
      alarmMove: {
        id: '',
        val: false
      },
      alarmWeekendMove: {
        id: '',
        val: false
      },
      alarmIgnition: {
        id: '',
        val: false
      },
      alarmSevere: {
        id: '',
        val: false
      },
      alarmIddle: {
        id: '',
        val: false
      },
      alarmLongTime: {
        id: '',
        val: false
      },
      alarmOverSpeed: {
        id: '',
        val: false,
        speed: 0
      },
      alarmHighFuelConsumption: {
        id: '',
        val: false,
        consumption: 0
      },
      alarmLowFuel: {
        id: '',
        val: false,
        fuel: 0
      },

      maxSpeed: '',
      fuel: '',
      consumption: '',
      longTime: ''


    }
  },
  created() {
    if (router.currentRoute.params.id != undefined) {
      this.fetchGroup()
      this.fetchAssetsOfAssetGroup()
    }
    this.fetchAlarms()
  },
  computed: {},
  methods: {
    validateLongTime() {
      if (this.longTime) {
        this.alarmLongTime.longTime = this.longTime
        this.createAlarmLongTime()
        this.hideModalLongTime()
      } else {
        this.longTime = ''
        this.alarmLongTime.longTime = 0
        this.alarmLongTime.val = false
        this.hideModalLongTime()
        this.msgDialog(this.$t('Enter a valid hour'), 'error')
      }
    },
    showModalLongTime() {
      this.alarmLongTime.longTime = ''
      this.$refs['modal-create-long-time'].show()
    },
    hideModalLongTime() {
      this.$refs['modal-create-long-time'].hide()
    },
    cancelLongTime() {
      this.longTime = ''
      this.alarmLongTime.longTime = 0
      this.alarmLongTime.val = false
      this.hideModalLongTime()

    },
    showModalOverSpeed() {
      this.$refs['modal-create-over-speed'].show()
    },
    hideModalOverSpeed() {
      this.$refs['modal-create-over-speed'].hide()
    },
    cancelOverSpeed() {
      this.maxSpeed = ''
      this.alarmOverSpeed.speed = 0
      this.alarmOverSpeed.val = false
      this.hideModalOverSpeed()

    },
    validateOverSpeed() {
      if (this.maxSpeed && this.maxSpeed > 0) {
        this.alarmOverSpeed.speed = this.maxSpeed
        this.createAlarmOverSpeed()
        this.hideModalOverSpeed()
      } else {
        this.maxSpeed = ''
        this.alarmOverSpeed.speed = 0
        this.alarmOverSpeed.val = false
        this.hideModalOverSpeed()
        this.msgDialog(this.$t('Enter a valid speed'), 'error')
      }
    },

    showModalHighFuelConsumption() {
      this.$refs['modal-create-high-fuel-consumption'].show()
    },
    hideModalHighFuelConsumption() {
      this.$refs['modal-create-high-fuel-consumption'].hide()
    },
    cancelHighFuelConsumption() {
      this.consumption = ''
      this.alarmHighFuelConsumption.consumption = 0
      this.alarmHighFuelConsumption.val = false
      this.hideModalHighFuelConsumption()

    },
    validateHighFuelConsumption() {
      if (this.consumption && this.consumption > 0) {
        this.alarmHighFuelConsumption.consumption = this.consumption
        this.createAlarmHighConsumption()
        this.hideModalHighFuelConsumption()
      } else {
        this.consumption = ''
        this.alarmHighFuelConsumption.consumption = 0
        this.alarmHighFuelConsumption.val = false
        this.hideModalHighFuelConsumption()
        this.msgDialog(this.$t('Enter a valid consumption'), 'error')
      }
    },

    showModalLowFuel() {
      this.$refs['modal-create-low-fuel'].show()
    },
    hideModalLowFuel() {
      this.$refs['modal-create-low-fuel'].hide()
    },
    cancelLowFuel() {
      this.fuel = ''
      this.alarmLowFuel.fuel = 0
      this.alarmLowFuel.val = false
      this.hideModalLowFuel()

    },
    validateLowFuel() {
      if (this.fuel && this.fuel > 0) {
        this.alarmLowFuel.fuel = this.fuel
        this.createAlarmLowFuel()
        this.hideModalLowFuel()
      } else {
        this.fuel = ''
        this.alarmLowFuel.fuel = 0
        this.alarmLowFuel.val = false
        this.hideModalLowFuel()
        this.msgDialog(this.$t('Enter a valid %'), 'error')
      }
    },
    createAlarmLongTime() {

      store.dispatch(`${this.ALARMS_APP_STORE_MODULE_NAME}/addLongTimeAlarm`, {
        assetGroupId: this.groupId,
        value: this.alarmLongTime.longTime
      })
          .then(response => {
            this.msgDialog(this.$t('Success in Enable'), 'success')
            this.fetchAlarms()

          })
          .catch(error => {
            this.alarmMove.val = !this.alarmMove.val
            this.fetchAlarms()
            this.msgDialog(this.$t('An error occurred while trying to enable'), 'error')

          })
    },
    //Create alarms
    createAlarmMove() {

      store.dispatch(`${this.ALARMS_APP_STORE_MODULE_NAME}/addMoveAlarm`, {
        assetGroupId: this.groupId
      })
          .then(response => {
            this.msgDialog(this.$t('Success in Enable'), 'success')

            this.fetchAlarms()

          })
          .catch(error => {
            this.alarmMove.val = !this.alarmMove.val
            this.fetchAlarms()
            this.msgDialog(this.$t('An error occurred while trying to enable'), 'error')

          })
    },
    createAlarmWeekendMove() {
      store.dispatch(`${this.ALARMS_APP_STORE_MODULE_NAME}/addAlarmWeekendMove`, {
        assetGroupId: this.groupId
      })
          .then(response => {
            this.msgDialog(this.$t('Success in Enable'), 'success')
            this.fetchAlarms()

          })
          .catch(error => {
            this.alarmWeekendMove.val = !this.alarmWeekendMove.val
            this.msgDialog(this.$t('An error occurred while trying to enable'), 'error')

          })
    },
    createAlarmIgnition() {
      store.dispatch(`${this.ALARMS_APP_STORE_MODULE_NAME}/addIgnitionAlarm`, {
        assetGroupId: this.groupId
      })
          .then(response => {
            this.msgDialog(this.$t('Success in Enable'), 'success')
            this.fetchAlarms()

          })
          .catch(error => {
            this.alarmIgnition.val = !this.alarmIgnition.val
            this.msgDialog(this.$t('An error occurred while trying to enable'), 'error')

          })
    },
    createAlarmSevere() {
      store.dispatch(`${this.ALARMS_APP_STORE_MODULE_NAME}/addSevereAlarm`, {
        assetGroupId: this.groupId
      })
          .then(response => {
            this.msgDialog(this.$t('Success in Enable'), 'success')
            this.fetchAlarms()

          })
          .catch(error => {
            this.alarmSevere.val = !this.alarmSevere.val
            this.msgDialog(this.$t('An error occurred while trying to enable'), 'error')

          })
    },
    createAlarmIddle() {
      store.dispatch(`${this.ALARMS_APP_STORE_MODULE_NAME}/addIddleAlarm`, {
        assetGroupId: this.groupId
      })
          .then(response => {
            this.msgDialog(this.$t('Success in Enable'), 'success')
            this.fetchAlarms()

          })
          .catch(error => {
            this.alarmIddle.val = !this.alarmIddle.val
            this.msgDialog(this.$t('An error occurred while trying to enable'), 'error')

          })
    },
    createAlarmOverSpeed() {
      store.dispatch(`${this.ALARMS_APP_STORE_MODULE_NAME}/addOverSpeedAlarm`, {
        assetGroupId: this.groupId,
        value: this.alarmOverSpeed.speed
      })
          .then(response => {
            this.msgDialog(this.$t('Success in Enable'), 'success')
            this.fetchAlarms()

          })
          .catch(error => {
            this.alarmOverSpeed.val = !this.alarmOverSpeed.val
            this.msgDialog(this.$t('An error occurred while trying to enable'), 'error')

          })
    },
    createAlarmHighConsumption() {
      store.dispatch(`${this.ALARMS_APP_STORE_MODULE_NAME}/addHighConsumptionAlarm`, {
        assetGroupId: this.groupId,
        value: this.alarmHighFuelConsumption.consumption
      })
          .then(response => {
            this.msgDialog(this.$t('Success in Enable'), 'success')
            this.fetchAlarms()

          })
          .catch(error => {
            this.alarmHighFuelConsumption.val = !this.alarmHighFuelConsumption.val
            this.msgDialog(this.$t('An error occurred while trying to enable'), 'error')

          })
    },
    createAlarmLowFuel() {
      store.dispatch(`${this.ALARMS_APP_STORE_MODULE_NAME}/addLowFuelAlarm`, {
        assetGroupId: this.groupId,
        value: this.alarmLowFuel.fuel
      })
          .then(response => {
            this.msgDialog(this.$t('Success in Enable'), 'success')
            this.fetchAlarms()

          })
          .catch(error => {
            this.alarmLowFuel.val = !this.alarmLowFuel.val
            this.msgDialog(this.$t('An error occurred while trying to enable'), 'error')

          })
    },

    //delete alarms
    deleteAlarm(id, typeAlarm) {
      store.dispatch(`${this.ALARMS_APP_STORE_MODULE_NAME}/deleteAlarm`, {
        id: id
      })
          .then(response => {
            if (typeAlarm === this.OVER_SPEED_ALARM_NAME) {
              this.alarmOverSpeed.val = false;
              this.alarmOverSpeed.speed = 0;
              this.maxSpeed = ''
            } else if (typeAlarm === this.HIGH_FUEL_CONSUMPTION_ALARM_NAME) {
              this.alarmHighFuelConsumption.val = false;
              this.alarmHighFuelConsumption.consumption = 0;
              this.consumption = ''
            } else if (typeAlarm === this.LOW_FUEL_ALARM_NAME) {
              this.alarmLowFuel.val = false;
              this.alarmLowFuel.fuel = 0;
              this.fuel = ''
            }
            this.msgDialog(this.$t('You disabled the alarm!'), 'warning')
          })
          .catch(error => {
            if (typeAlarm === this.OVER_SPEED_ALARM_NAME) {
              this.alarmOverSpeed.val = true;

            } else if (typeAlarm === this.HIGH_FUEL_CONSUMPTION_ALARM_NAME) {
              this.alarmHighFuelConsumption.val = true;

            } else if (typeAlarm === this.LOW_FUEL_ALARM_NAME) {
              this.alarmLowFuel.val = true;

            }
            this.$swal(this.$t('Error'), this.$t('An error occurred while trying to disable'), 'error')
          })

    },

    toogleAlarm(typeAlarm) {
      switch (typeAlarm) {
        case this.MOVE_ALARM_NAME:
          //this.alarmMove.model ? this.createAlarmMove() : this.deleteAlarm(this.alarmMove.id, this.MOVE_ALARM_NAME)
          this.alarmMove.val ? this.createAlarmMove() : this.deleteAlarm(this.alarmMove.id, this.MOVE_ALARM_NAME)
          break;
        case this.WEEKEND_MOVE_ALARM_NAME:
          this.alarmWeekendMove.val ? this.createAlarmWeekendMove() : this.deleteAlarm(this.alarmWeekendMove.id, this.WEEKEND_MOVE_ALARM_NAME)
          break;
        case this.IGNITION_ALARM_NAME:
          this.alarmIgnition.val ? this.createAlarmIgnition() : this.deleteAlarm(this.alarmIgnition.id, this.IGNITION_ALARM_NAME)
          break;
        case this.SEVERE_USE_ALARM_NAME:
          this.alarmSevere.val ? this.createAlarmSevere() : this.deleteAlarm(this.alarmSevere.id, this.SEVERE_USE_ALARM_NAME)
          break;
        case this.IDDLE_USE_ALARM_NAME:
          this.alarmIddle.val ? this.createAlarmIddle() : this.deleteAlarm(this.alarmIddle.id, this.IDDLE_USE_ALARM_NAME)
          break;
        case this.LONG_TIME_ALARM_NAME:
          this.alarmLongTime.val ? this.showModalLongTime() : this.deleteAlarm(this.alarmLongTime.id, this.LONG_TIME_ALARM_NAME)
          break;
        case this.OVER_SPEED_ALARM_NAME:
          this.alarmOverSpeed.val ? this.showModalOverSpeed() : this.deleteAlarm(this.alarmOverSpeed.id, this.OVER_SPEED_ALARM_NAME)
          break;
        case this.HIGH_FUEL_CONSUMPTION_ALARM_NAME:
          this.alarmHighFuelConsumption.val ? this.showModalHighFuelConsumption() : this.deleteAlarm(this.alarmHighFuelConsumption.id, this.HIGH_FUEL_CONSUMPTION_ALARM_NAME)
          break;
        case this.LOW_FUEL_ALARM_NAME:
          this.alarmLowFuel.val ? this.showModalLowFuel() : this.deleteAlarm(this.alarmLowFuel.id, this.LOW_FUEL_ALARM_NAME)
          break;
        default:
          this.msgDialog('FAILURE ', 'error')
      }
    },

    fetchAlarms() {
      store
          .dispatch(`${this.ALARMS_APP_STORE_MODULE_NAME}/fetchAlarms`, {
            assetGroupId: this.groupId,
          })
          .then((res) => {
            const alarms = res.data.data;

            if (alarms && alarms.length > 0) {
              const alarmMap = {
                [this.MOVE_ALARM_NAME]: { alarm: this.alarmMove },
                [this.WEEKEND_MOVE_ALARM_NAME]: { alarm: this.alarmWeekendMove },
                [this.IGNITION_ALARM_NAME]: { alarm: this.alarmIgnition },
                [this.SEVERE_USE_ALARM_NAME]: { alarm: this.alarmSevere },
                [this.IDDLE_USE_ALARM_NAME]: { alarm: this.alarmIddle },
                [this.OVER_SPEED_ALARM_NAME]: {
                  alarm: this.alarmOverSpeed,
                  extraProp: "speed",
                },
                [this.HIGH_FUEL_CONSUMPTION_ALARM_NAME]: {
                  alarm: this.alarmHighFuelConsumption,
                  extraProp: "consumption",
                },
                [this.LOW_FUEL_ALARM_NAME]: {
                  alarm: this.alarmLowFuel,
                  extraProp: "fuel",
                },
                [this.LONG_TIME_ALARM_NAME]: {
                  alarm: this.alarmLongTime,
                  extraProp: "longTime",
                },
              };

              alarms.forEach((alarm) => {
                const config = alarmMap[alarm.name];
                if (config) {
                  config.alarm.val = true;
                  config.alarm.id = alarm.id;
                  
                  if (config.extraProp && alarm.value) {
                    config.alarm[config.extraProp] = alarm.value;
                  }
                }
              });
            }
          })
          .catch((error) => {
            console.error("Erro ao buscar alarmes:", error);
          });
    },

    selectHandler(option) {
      //this.selected = option.item
      //this.searchQuery = option.item.plate ? option.item.plate : option.item.chassisNumber
      console.log('opt: ', option)
      this.asset.id = option.item.id
      this.asset.name = option.item.plate ? option.item.plate : option.item.chassisNumber
      this.filteredOptions = []


    },

    onInputChange(text) {
      if (text === '' || text === undefined || text.length < 3) {

        return
      }

      store.dispatch('app-management/fetchAssets', {
        q: text,
        excludeGrouped: true
      })
          .then(response => {

            this.filteredOptions = [{
              data: response.data.data,
            }]

          })
          .catch(error => {

            console.log(error)

          })
    },

    openModalBind(id) {
      console.log('abriu')
      this.showModal = true
      //this.groupId = id
    },

    limparFormCadastro() {
      this.asset = {
        name: '',
        id: ''
      }

      //this.groupId = ''
    },

    bindGroup() {
      this.showModal = false

      store.dispatch(`${this.MANAGEMENT_APP_STORE_MODULE_NAME}/bindAssetToAssetGroup`, {
        assetId: this.asset.id,
        assetGroupId: router.currentRoute.params.id
      })
          .then(response => {
            this.$swal('Success', this.$t('Asset added'), 'success')
            this.fetchAssetsOfAssetGroup()
          })
          .catch(error => {
            this.$swal('Error', '', 'error')

          })
    },

    modalDelete(assetId, bindId) {
      this.$swal({
        title: this.$t('Are you sure?'),
        text: this.$t("You can't revert your action"),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        showCloseButton: true,
        showLoaderOnConfirm: true,
        customClass: {
          cancelButton: 'cancelBtn',
          confirmButton: 'mr-1',
        }
      }).then((result) => {
        if (result.value) {

          this.unBindAssetToAssetGroup(assetId, bindId)

        } else {
        }
      })
    },

    unBindAssetToAssetGroup(assetId, bindId) {
      store.dispatch('app-management/unBindAssetToAssetGroup', {
        assetId: assetId,
        bindId: bindId,
        assetGroupId: this.myId

      })
          .then(response => {
            //this.$swal(this.$t("Deleted"), `${this.$t("You successfully deleted")}`, 'success')
            this.fetchAssetsOfAssetGroup()

          })
          .catch(error => {
            this.$swal('Error', 'Your fuelling is still intact', 'error')
            console.log(error);
          })
    },


    fetchAssetsOfAssetGroup() {

      store
          .dispatch('app-management/fetchAssetsOfAssetGroup', {
            groupId: router.currentRoute.params.id,
            perPage: this.perPage,
            page: this.currentPage,
          })
          .then(response => {
            this.assets = response.data.data
            this.totalAssets = response.data.totalItems
          })
          .catch(() => {

          })

    },


    fetchGroup() {
      store.dispatch('app-management/fetchGroup', {

        id: router.currentRoute.params.id
      })
          .then(response => {
            this.groupData = response.data
          })
          .catch(e => {
            console.log(e)
          })
    },

    removeAsset(index) {
      // if (index >= 0 && index < this.groupData.assets.length) {
      //     this.groupData.assets.splice(index, 1);
      // }
    },

    addAssets() {
      var newAsset = {
        name: '',
        assetId: '',
      }
      this.groupData.assets.push(newAsset)
    },

    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {


          if (this.groupData.id === "") {
            store.dispatch('app-management/addGroup', {

              groupData: this.groupData
            })
                .then(response => {
                  //alert('Error 404')
                  this.msgDialog('Successful ', 'success')

                  router.push({
                    name: 'list-group'
                  })

                })
                .catch(error => {
                  this.msgDialog('FAILURE ', 'error')
                  // TODO: FAZER MSG ESPESSIFICA PARA CADAS ERRO DA API
                })

          } else {

            store.dispatch('app-management/updateassetgroup', this.groupData)
                .then(response => {
                  //alert('Error 404')
                  this.msgDialog('Successful ', 'success')

                  router.push({
                    name: 'list-group'
                  })

                })
                .catch(error => {
                  this.msgDialog('FAILURE ', 'error')
                  // TODO: FAZER MSG ESPESSIFICA PARA CADAS ERRO DA API
                })
          }
        }
      })
    },

    msgDialog(msg, icon) {
      this.$swal({
        position: 'top-end',
        icon: icon,
        title: msg,
        showConfirmButton: false,
        timer: 1500 * 3,
        customClass: {
          confirmButton: 'btn btn-primary',
        },

        buttonsStyling: false,
      })
    },


    suggestionSelected() {
      //console.log('Selecionado', this.selected)
    }
  },
  setup() {

    const groupId = router.currentRoute.params.id 
    const MANAGEMENT_APP_STORE_MODULE_NAME = 'app-management'
    const ALARMS_APP_STORE_MODULE_NAME = 'app-alarms'

    const MOVE_ALARM_NAME = "Move Alarm"
    const WEEKEND_MOVE_ALARM_NAME = "Weekend Move Alarm"
    const IGNITION_ALARM_NAME = "Ignition Alarm"
    const SEVERE_USE_ALARM_NAME = "Severe use Alarm"
    const IDDLE_USE_ALARM_NAME = "Iddle use Alarm"
    const LOW_FUEL_ALARM_NAME = "Low Fuel Alarm"
    const HIGH_FUEL_CONSUMPTION_ALARM_NAME = "High Fuel Consumption Alarm"
    const OVER_SPEED_ALARM_NAME = "Overspeed Alarm"
    //const LONG_TIME_ALARM_NAME = "Long time without connection" Long time without connection alarm
    const LONG_TIME_ALARM_NAME = "Long time without connection alarm"

    // Register module
    if (!store.hasModule(MANAGEMENT_APP_STORE_MODULE_NAME)) store.registerModule(MANAGEMENT_APP_STORE_MODULE_NAME, managementStoreModule)
    if (!store.hasModule(ALARMS_APP_STORE_MODULE_NAME)) store.registerModule(ALARMS_APP_STORE_MODULE_NAME, alarmsStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MANAGEMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(MANAGEMENT_APP_STORE_MODULE_NAME)
      if (store.hasModule(ALARMS_APP_STORE_MODULE_NAME)) store.unregisterModule(ALARMS_APP_STORE_MODULE_NAME)
    })

    // Perfect Scrollbar
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }


    return {
      groupId,
      MANAGEMENT_APP_STORE_MODULE_NAME,
      ALARMS_APP_STORE_MODULE_NAME,
      perfectScrollbarSettings,

      //consts for names alarms
      MOVE_ALARM_NAME,
      WEEKEND_MOVE_ALARM_NAME,
      IGNITION_ALARM_NAME,
      SEVERE_USE_ALARM_NAME,
      IDDLE_USE_ALARM_NAME,
      OVER_SPEED_ALARM_NAME,
      LOW_FUEL_ALARM_NAME,
      HIGH_FUEL_CONSUMPTION_ALARM_NAME,
      LONG_TIME_ALARM_NAME,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style>
.title-head {
  margin-bottom: 32px;
}
.alarm-section {
  //padding: 1.5rem;
  border-bottom: 1px solid #ebe9f1;
}

.rise {
  transition-duration: .8s;
  transform: translate(0px, -26.5%);
}

.event-weekendmove {
  background-color: #ea5455 !important;
}

.day-table {
  background-color: #f3f2f7;
}

.style-dark {
  background-color: #d3d6dc !important;
}

.ps-customizer-area {
  height: calc(100% - 83px)
}

.per-page-selector {
  width: 90px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .8s ease;
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */

{
  opacity: 0;
  transition: opacity .8s ease;
}
</style>
