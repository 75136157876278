import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAllFuelling(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          //.get('/apps/user/users', { params: queryParams })
          .get(`/api/v1/Fuelling/search?AssetId=${queryParams.assetId}&Search=${queryParams.q}&Page=${queryParams.page}&PerPage=${queryParams.perPage}&Start=${queryParams.dtS}&End=${queryParams.dtE}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchFuelling(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          //.get(`/apps/user/users/${id}`)
          .get(`/api/v1/Fuelling?id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addFuelling(ctx, fuellingData) {
      return new Promise((resolve, reject) => {

        let fuelling = fuellingData.fuellingData
        axios
          //.post('/apps/customer/customers', { customer: customerData })
          .post('/api/v1/fuelling', fuelling)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }, //
    importFuelling(ctx, fuelling) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("file", fuelling);
        axios
          //.post('/apps/customer/customers', { customer: customerData })
          .post('/api/v1/integrations/file/UploadTicketLogFuellingFile', formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateFuelling(ctx, fuellingData) {
      return new Promise((resolve, reject) => {

        let fuelling = fuellingData.fuellingData
        axios
          //.post('/apps/customer/customers', { customer: customerData })
          .put('/api/v1/fuelling', fuelling)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteFuelling(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {

        axios
          //.post('/apps/customer/customers', { customer: customerData })
          .delete(`/api/v1/fuelling?Id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTypeFuel(ctx, ) {
      return new Promise((resolve, reject) => {

        axios
          .get('/api/v1/Fuelling/searchfueltype?Page=1&PerPage=11')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAssets(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        const excludeGrouped = queryParams.excludeGrouped ? "&ExcludeGrouped=" + queryParams.excludeGrouped : ""

        axios
          //.get('/apps/asset/assets', { params: queryParams })
          .get(`/api/v1/Asset/search?Search=${queryParams.q}${excludeGrouped}&Page=1&PerPage=5`)
          //.get(`/api/v1/Asset/search?Page=${queryParams.page}&PerPage=${queryParams.perPage}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // fetchAsset(ctx, {
    //   id
    // }) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       //.get(`/apps/asset/assets/${id}`)
    //       .get(`/api/v1/Asset?id=${id}`)
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },

    //Preventive Maintenance Plan
    fetchAllMaintenancePlan(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/Maintenance/SearchPreventiveMaintenancePlan?Search=${queryParams.q}&Page=${queryParams.page}&PerPage=${queryParams.perPage}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchMaintenancePlan(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          //.get(`/apps/user/users/${id}`)
          .get(`/api/v1/Maintenance/GetPreventiveMaintenancePlan?id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addMaintenancePlan(ctx, maintenanceData) {
      return new Promise((resolve, reject) => {
        console.log('addMaintenancePlan', maintenanceData)
        let maintenance = maintenanceData.maintenanceData
        axios
          //.post('/apps/customer/customers', { customer: customerData })
          .post('/api/v1/Maintenance/CreatePreventiveMaintenancePlan', maintenance)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    updateMaintenancePlan(ctx, maintenanceData) {
      return new Promise((resolve, reject) => {
        console.log('updateMaintenancePlan', maintenanceData)
        let maintenance = maintenanceData.maintenanceData
        axios
          .put('/api/v1/Maintenance/UpdatePreventiveMaintenancePlan', maintenance)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deleteMaintenancePlan(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/Maintenance/DeletePreventiveMaintenancePlan?Id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    //fetchPlansByAssetId
    fetchPlansByAssetId(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/Maintenance/SearchPreventiveMaintenancePlanOfAsset?AssetId=${queryParams.assetId}&Page=${queryParams.page}&PerPage=${queryParams.perPage}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    bindMaintenancePlan(ctx, bindMaintenance) {
      return new Promise((resolve, reject) => {
        console.log('bindMaintenancePlan: ', bindMaintenance)

        axios
          //.post('/apps/customer/customers', { customer: customerData })
          .post('/api/v1/Maintenance/BindPreventiveMaintenancePlan', bindMaintenance)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deleteBindPlan(ctx, bindPlan) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/Maintenance/UnbindPreventiveMaintenancePlan?AssetId=${bindPlan.assetId}&PreventiveMaintenancePlanId=${bindPlan.preventiveMaintenancePlanId}&AssetMaintenancePlanId=${bindPlan.assetMaintenancePlanId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchAssetsByPlanId(ctx, query) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/Maintenance/SearchAssetOfPreventiveMaintenancePlan?PreventiveMaintenancePlanId=${query.planId}&Page=${query.page}&PerPage=${query.perPage}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchMaintenancePlanSummary(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          //.get(`/apps/user/users/${id}`)
          .get(`/api/v1/Maintenance/GetAssetMaintenancePlanSummary?id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    ///api/v1/Asset/searchassetgroup?Page=1&PerPage=11
    fetchAllGroup(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/Asset/searchassetgroup?Search=${queryParams.q}&Page=${queryParams.page}&PerPage=${queryParams.perPage}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    addGroup(ctx, groupData) {
      return new Promise((resolve, reject) => {

        let group = groupData.groupData
        axios
          //.post('/apps/customer/customers', { customer: customerData })
          .post('/api/v1/Asset/createassetgroup', group)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchGroup(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          //.get(`/apps/user/users/${id}`)
          .get(`/api/v1/Asset/getassetgroup?id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchAssetsOfAssetGroup(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/Asset/SearchAssetsOfAssetGroup?AssetGroupId=${queryParams.groupId}&Page=${queryParams.page}&PerPage=${queryParams.perPage}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    bindAssetToAssetGroup(ctx, bind) {
      return new Promise((resolve, reject) => {
        axios
          //.post('/apps/customer/customers', { customer: customerData })
          .post('/api/v1/Asset/BindAssetToAssetGroup', bind)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    ///api/v1/Asset/deleteassetgroup
    deletAassetGroup(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/Asset/deleteassetgroup?Id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    updateassetgroup(ctx, group) {
      return new Promise((resolve, reject) => {
        axios
          //.post('/apps/customer/customers', { customer: customerData })
          .put('/api/v1/Asset/updateassetgroup', group)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },



    unBindAssetToAssetGroup(ctx, group) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/Asset/UnBindAssetToAssetGroup?AssetId=${group.assetId}&AssetGroupId=${group.assetGroupId ?? ""}&BindId=${group.bindId ?? ""}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },




    fetchAsset(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          //.get(`/apps/asset/assets/${id}`)
          .get(`/api/v1/Asset?id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
