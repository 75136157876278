<template>
<div>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">

        <div class="m-2">

            <!-- Table Top -->
            <b-row>

                <!-- Per Page -->
                <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                    <label>{{ $t('Show')}}</label>
                    <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
                    <label>{{ $t('entries')}}</label>
                </b-col>

                <!-- Search -->
                <b-col cols="12" md="6">
                    <div class="d-flex align-items-center justify-content-end">
                        <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
                        <b-button v-if="user.role.includes('SYSADMIN')" variant="primary" :to="{ name: 'register-manufacturer' }">
                            <span class="text-nowrap">{{ $t('Add Manufacturer')}}</span>
                        </b-button>
                    </div>
                </b-col>
            </b-row>

        </div>

        <b-table ref="refManufacturerListTable" class="position-relative" :items="fetchManufacturers" responsive :fields="tableColumns" primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No matching records found" :sort-desc.sync="isSortDirDesc">

            <!-- Column:  name -->
            <template #head(name)>
                {{ $t('Name')}}
            </template>
            <template #cell(name)="data">
                <div class="font-weight-bold d-block text-nowrap">
                    {{ data.item.name }}
                </div>
            </template>

            <!-- Column: Actions -->
            <template #head(actions)>
                {{ $t('Actions')}}
            </template>
            <template #cell(actions)="data">
                <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

                    <template #button-content>
                        <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                    </template>

                    <b-dropdown-item :to="{ name: 'register-manufacturer', params: { id: data.item.id } }">
                        <feather-icon icon="EditIcon" />
                        <span class="align-middle ml-50">{{ $t('Edit')}}</span>
                    </b-dropdown-item>

                   
                </b-dropdown>
            </template>

        </b-table>
        <div class="mx-2 mb-2">
            <b-row>

                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                    <span class="text-muted"> {{ $t('Showing')}} {{ dataMeta.from }} {{ $t('to')}} {{ dataMeta.to }} {{ $t('of')}} {{ dataMeta.of }} {{ $t('entries')}}</span>
                </b-col>
                <!-- Pagination -->
                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

                    <b-pagination v-model="currentPage" :total-rows="totalManufacturers" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>

                </b-col>

            </b-row>
        </div>
    </b-card>
</div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {
    ref,
    onUnmounted
} from '@vue/composition-api'
import {
    avatarText
} from '@core/utils/filter'
//import CustomersListFilters from './components/CustomersListFilters.vue'
import useManufacturerList from './useManufacturerList'
import manufacurerStoreModule from './manufacturerStoreModule'

export default {
    components: {

        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,

        vSelect,
    },
    methods: {
        modalDelete(myId) {
            this.$swal({
                title: this.$t('Are you sure?'),
                text: this.$t("You can't revert your action"),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: this.$t('Yes'),
                cancelButtonText: this.$t('No'),
                showCloseButton: true,
                showLoaderOnConfirm: true,
                customClass: {
                    cancelButton: 'cancelBtn',
                    confirmButton: 'mr-1',
                }
            }).then((result) => {
                if (result.value) {
                    console.log('ID', myId)
                    this.deleteDriver(myId)

                } else {}
            })
        },

        deleteDriver(myId) {
            store.dispatch('app-driver/deleteDriver', {
                    id: myId
                })
                .then(response => {
                    this.$swal(this.$t("Deleted"), `${this.$t("You successfully deleted")}`, 'success')
                        .then((result) => {
                            if (result.value) {
                                this.refetchData()

                            } else {}
                        })
                })
                .catch(error => {
                    this.$swal(this.$t('Error'), this.$t('An error occurred while trying to delete'), 'error')
                    console.log(error);
                })
        }
    },
    setup() {
        const MANUFACTURER_APP_STORE_MODULE_NAME = 'app-manufacturer'

        // Register module
        if (!store.hasModule(MANUFACTURER_APP_STORE_MODULE_NAME)) store.registerModule(MANUFACTURER_APP_STORE_MODULE_NAME, manufacurerStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(MANUFACTURER_APP_STORE_MODULE_NAME)) store.unregisterModule(MANUFACTURER_APP_STORE_MODULE_NAME)
        })

      const user = localStorage.userData != undefined ? JSON.parse(localStorage.userData) : {}

        const {
          fetchManufacturers,
          tableColumns,
          perPage,
          currentPage,
          totalManufacturers,
          dataMeta,
          perPageOptions,
          searchQuery,
          sortBy,
          isSortDirDesc,
          refManufacturerListTable,
          refetchData

        } = useManufacturerList()

        return {

            // Sidebar

          fetchManufacturers,
          tableColumns,
          perPage,
          currentPage,
          totalManufacturers,
          dataMeta,
          perPageOptions,
          searchQuery,
          sortBy,
          isSortDirDesc,
          refManufacturerListTable,
          refetchData,
          user

        }
    },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style><style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
