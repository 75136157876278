<template>
  <div class="main-menu menu-fixed menu-accordion menu-shadow" :class="[
        { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
        skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]" @mouseenter="updateMouseHovered(true)" @mouseleave="updateMouseHovered(false)">
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot name="header" :toggleVerticalMenuActive="toggleVerticalMenuActive" :toggleCollapsed="toggleCollapsed"
            :collapseTogglerIcon="collapseTogglerIcon">
        <ul class="nav navbar-nav flex-row">

          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link class="navbar-brand" to="/">
                            <span class="brand-logo" v-show="loadingLogo == false">
                                <b-img
                                    :style="!isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) ? { 'max-width': 140 + 'px', 'margin-left': 2 + 'rem', 'margin-top': -.5 + 'rem' } : ''"
                                    :src="resolveLogo()" alt="logo"/>
                            </span>
              <div style="height: 2rem;" v-show="loadingLogo == true"></div>
              <!--<h2 class="brand-text">
  {{ appName }}
</h2>-->
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon icon="XIcon" size="20" class="d-block d-xl-none"
                            @click="toggleVerticalMenuActive"/>
              <feather-icon :icon="collapseTogglerIconFeather" size="20"
                            class="d-none d-xl-block collapse-toggle-icon" @click="toggleCollapsed"/>
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom"/>

    <!-- main menu content-->
    <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="main-menu-content scroll-area" tagname="ul"
                           @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }">
      <vertical-nav-menu-items :items="navItens" class="navigation navigation-main"/>
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import customerSettigns from '@core/utils/customerSettigns'
import systemStoreModule from '@core/layouts/components/app-navbar/systemStoreModule'
import navMenuItems from '@/navigation/vertical'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  BLink,
  BImg
} from 'bootstrap-vue'
import {
  provide,
  computed,
  ref,
  onUnmounted
} from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import {
  $themeConfig
} from '@themeConfig'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'
import ContextMenuSimpleVue from '../../../../../views/extensions/context-menu/ContextMenuSimple.vue'
import store from '@/store'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  methods: {
    async getSetings() {
      this.loadingLogo = true
      this.userSettings = await customerSettigns()
      this.loadingLogo = false
    },

    resolveLogo() {
      // !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) ? require('@/assets/images/logo/LogoExtend.png') : require('@/assets/images/logo/logo.png')

      console.log(`userSettings`, this.userSettings)
      if (!this.userSettings) {
        if (!this.isVerticalMenuCollapsed || (this.isVerticalMenuCollapsed && this.isMouseHovered)) {
          return require('@/assets/images/logo/LogoExtend.png')
        }
        return require('@/assets/images/logo/logo.png')
      } else {
        if (!this.isVerticalMenuCollapsed || (this.isVerticalMenuCollapsed && this.isMouseHovered)) {
          return this.userSettings.logoUrl
        }
        return this.userSettings.iconUrl
      }

    }
  },
  data() {
    return {
      userSettings: null,
      loadingLogo: true
      //userSettings: localStorage.userSettings ? JSON.parse(localStorage.userSettings) : null,
    };

  },
  mounted() {
    const SYSTEM_APP_STORE_MODULE_NAME = 'app-system'

    // Register module
    if (!store.hasModule(SYSTEM_APP_STORE_MODULE_NAME)) store.registerModule(SYSTEM_APP_STORE_MODULE_NAME, systemStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SYSTEM_APP_STORE_MODULE_NAME)) store.unregisterModule(SYSTEM_APP_STORE_MODULE_NAME)
    })
    this.getSetings()
  },

  setup(props) {

    //const maxWidth = '36px'
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const {
      skin
    } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const {
      appName,
      appLogoImage
    } = $themeConfig.app

    const user = localStorage.userData != undefined ? JSON.parse(localStorage.userData) : {}

    const navItens = ref([])
    
    if(user.role == 'SYSADMIN'){
      navItens.value = navMenuItems
    }
    else if (user.role == 'TRCKOWN' || user.role == 'TRCKOWNVI' ) {
      navItens.value = navMenuItems.filter(function (f) {
        return f.route != 'list-all-device' && f.title != 'Admin'
      })
    } else if (user.role == 'SIMPlVI') {
      navItens.value = navMenuItems.filter(function (f) {
        return f.route == 'home' || f.route == 'map'
      })
    } else if (user.role == 'SERVSHAR' || user.role == 'SERVSHARVI') {
      if (user.plan == 'FinalConsumerCorporateConstruction') {
        navItens.value = navMenuItems.filter(function (f) {
          return f.route !== 'list-device'
        })
      } else {
        navItens.value = navMenuItems.filter(function (f) {
          return f.route !== 'list-device' 
        })
      }

    } else if (user.role == 'DEALER') {
      if (user.plan == 'FinalConsumerCorporateConstruction') {
        navItens.value = navMenuItems.filter(function (f) {
          return f.route !== 'list-device' && f.route !== 'list-dealer'
        })
      } else {
        navItens.value = navMenuItems.filter(function (f) {
          return f.route !== 'list-device' && f.route !== 'list-dealer'
        })
      }
    } else if (user.role == 'FINACON' || user.role == 'FINACONVI') {
      if (user.plan == 'FinalConsumerCorporateConstruction') {
        navItens.value = navMenuItems.filter(function (f) {
          return f.route !== 'list-device' && f.route !== 'list-customer' && f.route !== 'list-dealer'
        })
      } else {
        navItens.value = navMenuItems.filter(function (f) {
          return f.route !== 'list-device' && f.route !== 'list-customer' && f.route !== 'list-dealer' && f.route !== 'assets-dashboards'
        })
      }
    }
    //serviceSharer

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,

      //Itens SideBar
      user,
      navItens,

    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";

/*
.main-menu .navbar-header .navbar-brand .brand-logo  img {

}*/
</style>
