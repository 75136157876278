<template>
  <div>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">

      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t('Show') }}</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
                      :clearable="false" class="per-page-selector d-inline-block mx-50"/>
            <label>{{ $t('entries') }}</label>
          </b-col>

          <!-- Manufacturer -->
          <b-col  cols="12" md="4">
              <vue-autosuggest v-model="searchQueryManufacturer" :suggestions="filteredOptions" :input-props="inputProps" @selected="selectHandler" @input="onInputChange">
                <template slot-scope="{suggestion}">
                  <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
                </template>
              </vue-autosuggest>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="4">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..."/>
              <b-button v-if="user.role.includes('SYSADMIN')" variant="primary" :to="{ name: 'register-model' }">
                <span class="text-nowrap">{{ $t('Add Model') }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
          

      </div>

      <b-table ref="refModelListTable" class="position-relative" :items="fetchModels" responsive
               :fields="tableColumns" primary-key="id" :sort-by.sync="sortBy" show-empty
               empty-text="No matching records found" :sort-desc.sync="isSortDirDesc">

        <!-- Column: Manufacturer name -->
        <template #head(assetManufacturerName)>
          {{ $t('Manufacturer') }}
        </template>
        <template #cell(assetManufacturerName)="data">
          <div class="font-weight-bold d-block text-nowrap">
            {{ data.item.assetManufacturerName }}
          </div>
        </template>

        <!-- Column:  name -->
        <template #head(name)>
          {{ $t('Name') }}
        </template>
        <template #cell(name)="data">
          <div class="font-weight-bold d-block text-nowrap">
            {{ data.item.name }}
          </div>
        </template>

        <!-- Column: Actions -->
        <template #head(actions)>
          {{ $t('Actions') }}
        </template>
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
            </template>

            <b-dropdown-item :to="{ name: 'register-model', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">{{ $t('Edit') }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted"> {{ $t('Showing') }} {{ dataMeta.from }} {{ $t('to') }} {{
                dataMeta.to
              }} {{ $t('of') }} {{ dataMeta.of }} {{ $t('entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

            <b-pagination v-model="currentPage" :total-rows="totalModels" :per-page="perPage" first-number last-number
                          class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18"/>
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18"/>
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {
  ref,
  onUnmounted
} from '@vue/composition-api'
import {
  avatarText
} from '@core/utils/filter'

import useModelList from './useModelList'
import modelStoreModule from './modelStoreModule'
import manufacturerStoreModule from "../asset_manufacturer/manufacturerStoreModule";

import {
  VueAutosuggest
} from 'vue-autosuggest'

export default {
  components: {
    BFormGroup,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    VueAutosuggest
  },
  data(){
    return{
      searchQueryManufacturer: '',
      //manufacturerId :'',
      datasuggest: [],
      filteredOptions: [],
      inputProps: {
        id: 'autosuggest__input',
        class: 'form-control',
        placeholder: "Search for your manufacturer ...",
      },
      limit: 5,
      selected: null,
    }
  },
  methods: {
    selectHandler(option) {
      this.selected = option.item
      this.filteredOptions = []
      this.searchQueryManufacturer = option.item.name
      this.manufacturerId = option.item.id

    },

    onInputChange(text) {
      if (text === '' || text === undefined || text.length < 3) {
        this.manufacturerId = ''
        return
      }

      store.dispatch('app-manufacturer/fetchManufacturers', {
        query: this.searchQueryManufacturer,
        page: 1,
        perPage: 5
      })
          .then(response => {

            this.filteredOptions = [{
              data: response.data.data,
            }]

          })
          .catch(error => {

            console.log(error)

          })
    },
    suggestionSelected() {
      
    }
  },
  setup() {
    const MODEL_APP_STORE_MODULE_NAME = 'app-model'
    const MANUFACTURER_APP_STORE_MODULE_NAME = 'app-manufacturer'

    // Register module
    if (!store.hasModule(MODEL_APP_STORE_MODULE_NAME)) store.registerModule(MODEL_APP_STORE_MODULE_NAME, modelStoreModule)
    if (!store.hasModule(MANUFACTURER_APP_STORE_MODULE_NAME)) store.registerModule(MANUFACTURER_APP_STORE_MODULE_NAME, manufacturerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODEL_APP_STORE_MODULE_NAME)) store.unregisterModule(MODEL_APP_STORE_MODULE_NAME)
      if (store.hasModule(MANUFACTURER_APP_STORE_MODULE_NAME)) store.unregisterModule(MANUFACTURER_APP_STORE_MODULE_NAME)
    })

    const user = localStorage.userData != undefined ? JSON.parse(localStorage.userData) : {}

    const {
      fetchModels,
      tableColumns,
      perPage,
      currentPage,
      totalModels,
      dataMeta,
      perPageOptions,
      searchQuery,
      manufacturerId,
      sortBy,
      isSortDirDesc,
      refModelListTable,
      refetchData

    } = useModelList()

    return {

      fetchModels,
      tableColumns,
      perPage,
      currentPage,
      totalModels,
      dataMeta,
      perPageOptions,
      searchQuery,
      manufacturerId,
      sortBy,
      isSortDirDesc,
      refModelListTable,
      refetchData,

      // Filter
      avatarText,
      user

    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
