import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchLocationHistory(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/api/v1/Telemetry/search?AssetId=${queryParams.id}&Start=${queryParams.dtS}&End=${queryParams.dtE}&Page=${queryParams.page}&PerPage=100`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        }, // /api/v1/queries/telemetry/journey?AssetId=08fc6271-8893-4d42-8245-47833dda0189&Day=2022-09-22T19%3A05%3A23.248Z&Page=1&PerPage=111
        fetchJourney(ctx, queryParams) {

            const {
                id,
                date,
                startHour = '',
                endHour = '',
                page = 1
            } = queryParams;
            
            return new Promise((resolve, reject) => {
                axios
                    .get('/api/v1/queries/telemetry/journey', {
                        params:{
                            assetId: id,
                            day: date,
                            startHour: startHour,
                            endHour: endHour,
                            page,
                            perPage: 1
                        }
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchRouteHistory(ctx, queryParams) {

            const {
                id,
                startDate,
                endDate = '',
                startHour = '',
                endHour = '',
                page = 1
            } = queryParams;

            const getAddress = !!queryParams.getAddress

            const perPage = queryParams.getAddress ? 10 : 100

            return new Promise((resolve, reject) => {
                axios
                    .get(`/api/v1/queries/telemetry/locationhistory`, {
                        params: {
                            AssetId: id,
                            StartDate: startDate,
                            StartHour: startHour,
                            EndDate: endDate,
                            EndHour: endHour,
                            GetAddress: getAddress,
                            Page: page,
                            PerPage: perPage,
                        },
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchDailyWorkSummary(ctx, queryParams) {
            console.log(queryParams.dtE, queryParams.dtS)
            return new Promise((resolve, reject) => {
                axios
                    .get(`/api/v1/queries/telemetry/dailyworksummary?AssetId=${queryParams.id}&Start=${queryParams.dtS}&End=${queryParams.dtE}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchPlaces(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/api/v1/queries/telemetry/places?AssetId=${queryParams.id}&DayStart=${queryParams.dtS}&DayEnd=${queryParams.dtE}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchAsset(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    //.get(`/apps/asset/assets/${id}`)
                    .get(`/api/v1/Asset?id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        exportJourneyToPDF(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios({
                    url: `/api/v1/queries/telemetry/journeypdf?AssetId=${queryParams.id}&Day=${queryParams.date}`,
                    method: 'GET',
                    responseType: 'blob', // important
                })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        exportLocationHistoryToPDF(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios({
                    url: `/api/v1/queries/telemetry/locationhistorypdf?AssetId=${queryParams.id}&Day=${queryParams.date}&Start=${queryParams.hS}&End=${queryParams.hE}`,
                    method: 'GET',
                    responseType: 'blob', // important
                })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        exportLocationHistoryToExcel(ctx, queryParams) {
            const {
                id,
                startDate,
                endDate = '',
                startHour = '',
                endHour = '',
            } = queryParams;
            
           

            return new Promise((resolve, reject) => {
                axios
                    .get(`/api/v1/queries/telemetry/locationhistoryexcel`, {
                        params: {
                            AssetId: id,
                            StartDate: startDate,
                            StartHour: startHour,
                            EndDate: endDate,
                            EndHour: endHour,
                        },
                        responseType: 'blob'
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        exportMonthlySummaryToExcel(ctx, queryParams) {

            console.log('Data inicial: ', queryParams.start)
            console.log('Data Final: ', queryParams.end)
            return new Promise((resolve, reject) => {
                axios({
                    url: `/api/v1/queries/telemetry/dailyworksummaryexcel?AssetId=${queryParams.assetId}&Start=${queryParams.start}&End=${queryParams.end}`,
                    method: 'GET',
                    responseType: 'blob', // important
                })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        fetchAssetWorkstatusHistory(ctx, {id, dt}) {

            return new Promise((resolve, reject) => {
                axios
                    //.get(`/apps/asset/assets/${id}`)
                    .get(`/api/v1/queries/telemetry/workstatushistory?AssetId=${id}&Day=${dt}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        fetchTelemetrylocationhistory(ctx, queryParams) {

            return new Promise((resolve, reject) => {
                axios
                    .get(`/api/v1/queries/telemetry/telemetrylocationhistory?AssetId=${queryParams.id}&Day=${queryParams.date}&Start=${queryParams.hS}&End=${queryParams.hE}&Page=${queryParams.page}&PerPage=100`)
                    // /api/v1/queries/telemetry/locationhistory?AssetId=${queryParams.id}&Day=${queryParams.date}&Start=${queryParams.hS}&End=${queryParams.hE}&Page=${queryParams.page}&PerPage=100
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchTelemetryHistory(ctx, queryParams) {
            const {assetId, date, startTime, endTime, page} = queryParams;
            const perPage = 25
            return new Promise((resolve, reject) => {
                axios
                    .get(`/api/v1/queries/telemetry/telemetrytablehistory?AssetId=${assetId}&Day=${date}&Start=${startTime}&End=${endTime}&Page=${page}&PerPage=${perPage}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        //fetchSearchAvailableParameters
        fetchSearchAvailableParameters(ctx, queryParams) {
            const {initalDate, finalDate, assetId} = queryParams;
            return new Promise((resolve, reject) => {
                axios
                    .get(`/api/v1/queries/telemetry/SearchAvailableParameters?AssetId=${assetId}&Start=${initalDate}&End=${finalDate}&HasValidGps=true`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        searchTelemetry(ctx, queryParams) {
            const {initalDate, finalDate, assetId, page} = queryParams;
            return new Promise((resolve, reject) => {
                axios
                    .get(`/api/v1/Telemetry/search?AssetId=${assetId}&Start=${initalDate}&End=${finalDate}&Page=${page}&PerPage=25&HasValidGps=true`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        exportWeeklyWorksatusToExcel(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios({
                    url: `/api/v1/queries/telemetry/weeklyworkstatusexcel?AssetId=${queryParams.assetId}&StartDate=${queryParams.startDate}&EndDate=${queryParams.endDate}`,
                    method: 'GET',
                    responseType: 'blob', // important
                })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        fetchFailureReport(ctx, queryParams) {

            const {
                assetId= '',
                customerId= '',
                groupId= '',
                dayStart,
                dayEnd = '',
                page = 1,
                perPage= 10
            } = queryParams;

            return new Promise((resolve, reject) => {
                axios
                    .get(`/api/v1/queries/telemetry/failurereport`, {
                        params: {
                            AssetId: assetId,
                            CustomerId: customerId,
                            GroupId: groupId,
                            DayStart: dayStart,
                            DayEnd: dayEnd,
                            Page: page,
                            PerPage: perPage,
                        },
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

    },
}
