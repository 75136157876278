<template>
  <b-card>

    <validation-observer ref="simpleRules">
      <b-form>
        <!-- Section Informations -->
        <h4>{{ $t('Informations') }}</h4>
        <span class="font-weight-bold">{{ $t('The fields with (*) are required') }}</span>
        <hr class="mb-2">
        <b-row>

          <!-- Field: Name -->
          <b-col cols="12" md="4">
            <b-form-group :label="$t('Name')+'*'" label-for="name">
              <validation-provider #default="{ errors }" name="name" rules="required|min:3">
                <b-form-input id="name" v-model="manufacturerData.name"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>


          <!-- Field: Notes -->
          <b-col cols="12" md="12">
            <b-form-group :label="$t('Description')" label-for="description">
              <b-form-textarea v-model="manufacturerData.description" id="description" rows="5"/>
            </b-form-group>
          </b-col>

          <!-- Btn: Save -->
          <b-col v-if="user.role.includes('SYSADMIN')" cols="12">
            <b-button variant="primary" type="submit" @click.prevent="validationForm">
              {{ $t('Save') }}
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
} from 'bootstrap-vue'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BFormTextarea,
} from 'bootstrap-vue'
import {
  ref,
  onUnmounted
} from '@vue/composition-api'
import router from '@/router'
import store from '@/store'

import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.br'

import vSelect from 'vue-select'

import flatPickr from 'vue-flatpickr-component'

import {
  VueAutosuggest
} from 'vue-autosuggest'


import {
  BFormDatepicker
} from 'bootstrap-vue'

import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate'
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from '@validations'
import manufacturerStoreModule from "@/views/main/asset_manufacturer/manufacturerStoreModule";


export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCardText,
    BFormTextarea,

    Cleave,
    vSelect,
    BFormDatepicker,
    VueAutosuggest,
    flatPickr


  },
  data() {
    return {
      required,
      email,
      confirmed,
      url,
      between,
      alpha,
      integer,
      password,
      min,
      digits,
      alphaDash,
      length,
    }
  },

  methods: {
    msgDialog(msg, icon) {
      this.$swal({
        position: 'top-end',
        icon: icon,
        title: msg,
        showConfirmButton: false,
        timer: 1500 * 3,
        customClass: {
          confirmButton: 'btn btn-primary',
        },

        buttonsStyling: false,
      })
    },

    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          //this.driverData.birthDate = this.adjustDate(this.driverData.birthDate)
          if (!this.manufacturerData.id) {
            store.dispatch('app-manufacturer/addManufacturer', {
              manufacturerData: this.manufacturerData
            })
                .then(response => {
                  //alert('Error 404')
                  this.msgDialog(this.$t('Successful registration'), 'success')
                  router.push({
                    name: 'list-manufacturer'
                  })

                })
                .catch(error => {
                  this.msgDialog(this.$t('Failed to save'), 'error')
                })
          } else {

            store.dispatch('app-manufacturer/updateManufacturer', {

              manufacturerData: this.manufacturerData
            })
                .then(response => {
                  //alert('Error 404')
                  this.msgDialog(this.$t('Successful update'), 'success')
                  router.push({
                    name: 'list-manufacturer'
                  })


                })
                .catch(error => {
                  this.msgDialog(this.$t('FAILURE to update'), 'error')
                })
          }
        }
      })
    },

  },
  setup() {
    const manufacturerData = ref(null)
    const MANUFACTURE_APP_STORE_MODULE_NAME = 'app-manufacturer'

    const user = localStorage.userData != undefined ? JSON.parse(localStorage.userData) : {}

    // Register module
    if (!store.hasModule(MANUFACTURE_APP_STORE_MODULE_NAME)) store.registerModule(MANUFACTURE_APP_STORE_MODULE_NAME, manufacturerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MANUFACTURE_APP_STORE_MODULE_NAME)) store.unregisterModule(MANUFACTURE_APP_STORE_MODULE_NAME)
    })

    if (router.currentRoute.params.id) {

      store.dispatch('app-manufacturer/fetchManufacturer', {
        id: router.currentRoute.params.id
      })
          .then(response => {
            manufacturerData.value = response.data
          })
          .catch(error => {
            if (error.response.status === 404) {
              //driverData.value = undefined
            }
          })
    } else {
      manufacturerData.value = {
        id: '',
        name: '',
        description: ''
      }
    }

    return {
      manufacturerData,
      user
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style>
.title-head {
  margin-bottom: 32px;
}
</style>
