import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useModelList() {
  // Use toast
  const toast = useToast()

  const refModelListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', sortable: false },
    { key: 'assetManufacturerName', sortable: false },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalModels = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const manufacturerId = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  

  const dataMeta = computed(() => {
    const localItemsCount = refModelListTable.value ? refModelListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalModels.value,
    }
  })

  const refetchData = () => {
    refModelListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, manufacturerId], () => {
    refetchData()
  })

  const fetchModels = (ctx, callback) => {
    store
      .dispatch('app-model/fetchModels', {
        query: searchQuery.value,
        manufacturerId: manufacturerId.value,
        perPage: perPage.value,
        page: currentPage.value
      })
      .then(response => {
        const models = response.data.data;
        callback(models)
        totalModels.value = response.data.totalItems
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching models list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  

  return {
    fetchModels,
    tableColumns,
    perPage,
    currentPage,
    totalModels,
    dataMeta,
    perPageOptions,
    searchQuery,
    manufacturerId,
    sortBy,
    isSortDirDesc,
    refModelListTable,
    refetchData
  }
}
