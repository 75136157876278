export default [
    {
        title: 'Assets',
        route: 'home',
        icon: 'HomeIcon',
    },
    {
        title: 'Map',
        route: 'map',
        icon: 'MapIcon',
    },
    {
        title: 'Users',
        route: 'list-user',
        icon: 'UserIcon',
    },
    {
        title: 'Customers',
        route: 'list-customer',
        icon: 'UsersIcon',
    },
    {
        title: 'Dealers',
        route: 'list-dealer',
        icon: 'UserCheckIcon',
    },
    {
        title: 'Devices',
        route: 'list-device',
        icon: 'HardDriveIcon',
    },
       
    {
        title: 'Drivers',
        route: 'list-driver',
        icon: 'TruckIcon',
    },
    {
        title: 'Admin',
        icon: 'CoffeeIcon',
        children: [
            {
                title: 'Manufacturer',
                route: 'list-manufacturer'
            },
            {
                title: 'Models',
                route: 'list-model'
            },
            {
                title: 'All Devices',
                route: 'list-all-device',
            },
        ]
    },
    
    {
        title: 'Reports',
        icon: 'FileTextIcon',
        children: [
            {
                title: 'Measurement',
                route: 'report-usage'
            },
            {
                title: 'Usage Profile',
                route: 'assets-dashboards',
            },
        ]
    },
    {
        title: 'Management',
        icon: 'SettingsIcon',
        children: [
          {
            title: 'Fuelling',
            route: 'list-fuelling',
          },
          {
            title: 'Group',
            route: 'list-group',
          },
          
        ],
    },
    {
        title: 'Maintenance',
        icon: 'CalendarIcon',
        children: [
            {
                title: 'Plan/Preventive',
                route: 'list-maintenance-plan',
            },
            {
                title: 'Failures',
                route: 'customer-failure',
            },
        ],
    },
]