import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useAssetList() {
    // Use toast
    const toast = useToast()

    const refAssetListTable = ref(null)

    // Table Handlers
    const tableColumns = [
        { key: 'ownername', label: 'Owner', sortable: true },
        { key: 'costCenter', label: 'Cost Center', sortable: false },
        { key: 'chassis', label: 'Identifier', sortable: true },
        { key: 'modelName', label: 'Model', sortable: false },
        { key: 'lastconnection', label: 'Last Connection', sortable: true },
        //{ key: 'total', sortable: true, formatter: val => `$${val}` },
        //{ key: 'issuedDate', sortable: true },
        { key: 'workstatus', label: 'Status', sortable: true },
        //{ key: 'balance', label: 'Status', sortable: true },
        { key: 'actions' },
    ]
    const perPage = ref(10)
    const totalAssets = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('workstatus')
    const isSortDirDesc = ref(true)
    const sortDesc = ref(false)
    const statusFilter = ref(null)
    const customerId = ref('')
    const chosenStatus = ref([])

    const dataMeta = computed(() => {
        const localItemsCount = refAssetListTable.value ? refAssetListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalAssets.value,
        }
    })

    const refetchData = () => {
        refAssetListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery, statusFilter, customerId, chosenStatus], () => {
        refetchData()
    })

    const fetchAssets = (ctx, callback) => {
        store
            .dispatch('app-asset/fetchAssets', {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: sortDesc.value,
                status: statusFilter.value,
                customerId: customerId.value,
                workStatusList:chosenStatus.value
            })
            .then(response => {
                console.log(response.data);

                //const { assets, total } = response.data
                const assets = response.data.data;
                const total = response.data.length;
                console.log('==DEALER NAME: ',assets.dealerName)

                callback(assets)
                totalAssets.value = response.data.totalItems
            })
            .catch((e) => {
                console.log(e);
                toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error fetching assets' list",
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const resolveAssetStatusVariantAndIcon = status => {
        if (status === 'on') return { variant: 'light-success', icon: 'CheckCircleIcon' }
        if (status === 'off') return { variant: 'light-secondary', icon: 'MinusCircleIcon' }
        if (status === 'severe') return { variant: 'light-warning', icon: 'AlertCircleIcon' }
        if (status === 'iddle') return { variant: 'light-info', icon: 'AlertTriangleIcon' }
        if (status === 'noconnection') return { variant: 'light-primary', icon: 'SlashIcon' }
        if (status === 'failure') return { variant: 'light-danger', icon: 'XOctagonIcon' }
        return { variant: 'light-primary', icon: 'SlashIcon' }
    }

    const resolveModelAvatarVariant = status => {
        if (status === 'failure') return 'light-danger'
        if (status === 'off') return 'light-secondary'
        if (status === 'noconnection') return 'light'
        if (status === 'severe') return 'light-warning'
        if (status === 'iddle') return 'light-info'
        if (status === 'on') return 'light-success'
        return 'light-primary'
    }

    const resolveIcon = (assetType, iconUrl) => {
        if (iconUrl) return iconUrl;
        if (assetType === 1) return require('@/assets/images/assets/Car.png')
        if (assetType === 2) return require('@/assets/images/assets/Motorcycle.png')
        if (assetType === 3) return require('@/assets/images/assets/Truck.png')
        if (assetType === 4) return require('@/assets/images/assets/TruckTractor.png')
        if (assetType === 5) return require('@/assets/images/assets/Van.png')
        if (assetType === 6) return require('@/assets/images/assets/Bus.png')
        if (assetType === 7) return require('@/assets/images/assets/Pickup.png')
        if (assetType === 8) return require('@/assets/images/assets/Excavator.png')
        if (assetType === 9) return require('@/assets/images/assets/Grader.png')
        if (assetType === 10) return require('@/assets/images/assets/WheelLoader.png')
        if (assetType === 11) return require('@/assets/images/assets/Dozer.png')
        if (assetType === 12) return require('@/assets/images/assets/BackhoeLoader.png')
        if (assetType === 13) return require('@/assets/images/assets/SkidSteerLoader.png')
        if (assetType === 14) return require('@/assets/images/assets/Boat.png')
        if (assetType === 15) return require('@/assets/images/assets/JetSki.png')
        if (assetType === 16) return require('@/assets/images/assets/BoxTruck.png')
        if (assetType === 17) return require('@/assets/images/assets/MixerTruck.png')
        if (assetType === 18) return require('@/assets/images/assets/WaterTruck.png')
        if (assetType === 19) return require("@/assets/images/assets/CraneTruck.png");
        if (assetType === 20) return require("@/assets/images/assets/RoadRoller.png");
        if (assetType === 21) return require("@/assets/images/assets/ForkLift.png");
        if (assetType === 22) return require("@/assets/images/assets/ScissorLift.png");
        if (assetType === 23) return require("@/assets/images/assets/ForkLiftCabin.png");
        if (assetType === 24) return require("@/assets/images/assets/ContainerLifter.png");
        if (assetType === 25) return require("@/assets/images/assets/ArticulatedLift.png");
        if (assetType === 26) return require("@/assets/images/assets/CombineHarvester.png");
        if (assetType === 27) return require("@/assets/images/assets/SealcoatTruck.png");
        if (assetType === 28) return require("@/assets/images/assets/IrrigationPivot.png");
        if (assetType === 29) return require("@/assets/images/assets/SecurityHelmet.png");
        if (assetType === 30) return require("@/assets/images/assets/CropSprayer.png");
        if (assetType === 36) return require("@/assets/images/assets/StationaryEngine.png");
        if (assetType === 37) return require("@/assets/images/assets/DumpTruck.png");

        return require('@/assets/images/assets/Car.png')

    }

    return {
        fetchAssets,
        tableColumns,
        perPage,
        currentPage,
        totalAssets,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refAssetListTable,
        sortDesc,

        statusFilter,
        resolveModelAvatarVariant,
        resolveAssetStatusVariantAndIcon,

        refetchData,
        resolveIcon,

        customerId,
        chosenStatus
    }
}