<template>
  <b-card>

    <validation-observer ref="simpleRules">
      <b-form>
        <!-- Section Informations -->
        <h4>{{ $t('Informations') }}</h4>
        <span class="font-weight-bold">{{ $t('The fields with (*) are required') }}</span>
        <hr class="mb-2">
        <b-row>

          <!-- Field: Manufacturer -->
          <b-col cols="12" md="4">
            <b-form-group :label="$t('Manufacturer') + '*'" label-for="manufacturer">
              <vue-autosuggest v-model="searchQuery" :suggestions="filteredOptions"
                               :input-props="inputProps" @selected="selectHandler" @input="onInputChange">
                <template slot-scope="{suggestion}">
                  <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
                </template>
              </vue-autosuggest>
            </b-form-group>
          </b-col>

          <!-- Field: Name -->
          <b-col cols="12" md="4">
            <b-form-group :label="$t('Name')+'*'" label-for="name">
              <validation-provider #default="{ errors }" name="name" rules="required|min:3">
                <b-form-input id="name" v-model="modelData.name"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: icon url -->
          <b-col cols="12" md="4">
            <b-form-group :label="$t('Icon Url')" label-for="icon_url">
                <b-form-input id="icon_url" v-model="modelData.iconUrl"/>
            </b-form-group>
          </b-col>

          <!-- Btn: Save -->
          <b-col v-if="user.role.includes('SYSADMIN')" cols="12">
            <b-button variant="primary" type="submit" @click.prevent="validationForm">
              {{ $t('Save') }}
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
} from 'bootstrap-vue'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BFormTextarea,
} from 'bootstrap-vue'
import {
  ref,
  onUnmounted
} from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import modelStoreModule from './modelStoreModule'
import manufacturerStoreModule from '../asset_manufacturer/manufacturerStoreModule'

import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.br'

import vSelect from 'vue-select'

import flatPickr from 'vue-flatpickr-component'

import {
  VueAutosuggest
} from 'vue-autosuggest'


import {
  BFormDatepicker
} from 'bootstrap-vue'

import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate'
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from '@validations'


export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCardText,
    BFormTextarea,

    Cleave,
    vSelect,
    BFormDatepicker,
    VueAutosuggest,
    flatPickr


  },
  data() {
    return {
      required,
      email,
      confirmed,
      url,
      between,
      alpha,
      integer,
      password,
      min,
      digits,
      alphaDash,
      length,

      datasuggest: [],
      filteredOptions: [],
      inputProps: {
        id: 'autosuggest__input',
        class: 'form-control',
        placeholder: "Search for your customer ...",
      },
      limit: 3,
      selected: null,


    }
  },

  methods: {


    msgDialog(msg, icon) {
      this.$swal({
        position: 'top-end',
        icon: icon,
        title: msg,
        showConfirmButton: false,
        timer: 1500 * 3,
        customClass: {
          confirmButton: 'btn btn-primary',
        },

        buttonsStyling: false,
      })
    },


    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (!this.modelData.id) {
            store.dispatch('app-model/addModel', {
              modelData: this.modelData
            })
                .then(response => {
                  //alert('Error 404')
                  this.msgDialog(this.$t('Successful registration'), 'success')
                  router.push({
                    name: 'list-model'
                  })

                })
                .catch(error => {
                  this.msgDialog(this.$t('Failed to save'), 'error')
                  // TODO: FAZER MSG ESPESSIFICA PARA CADAS ERRO DA API
                })
          } else {

            store.dispatch('app-model/updateManufacturer', {
              modelData: this.modelData
            })
                .then(response => {
                  //alert('Error 404')
                  this.msgDialog(this.$t('Successful update'), 'success')
                  router.push({
                    name: 'list-model'
                  })
                })
                .catch(error => {
                  this.msgDialog(this.$t('FAILURE to update'), 'error')

                })
          }
        }
      })
    },

    selectHandler(option) {
      this.selected = option.item
      this.filteredOptions = []
      this.searchQuery = option.item.name
      this.modelData.assetManufacturerId = option.item.id
    },

    onInputChange(text) {
      if (text === '' || text === undefined || text.length < 3) {

        return
      }

      store.dispatch('app-manufacturer/fetchManufacturers', {
        query: this.searchQuery,
        page: 1,
        perPage: 5
      })
          .then(response => {
            this.filteredOptions = [{
              data: response.data.data,
            }]
          })
          .catch(error => {
            console.log(error)
          })
    },
    suggestionSelected() {
      
    }


  },
  setup() {
    const modelData = ref(null)

    const searchQuery = ref('')

    const MODEL_APP_STORE_MODULE_NAME = 'app-model'
    const MANUFACTURER_APP_STORE_MODULE_NAME = 'app-manufacturer'

    const user = localStorage.userData != undefined ? JSON.parse(localStorage.userData) : {}

    // Register module
    if (!store.hasModule(MODEL_APP_STORE_MODULE_NAME)) store.registerModule(MODEL_APP_STORE_MODULE_NAME, modelStoreModule)
    if (!store.hasModule(MANUFACTURER_APP_STORE_MODULE_NAME)) store.registerModule(MANUFACTURER_APP_STORE_MODULE_NAME, manufacturerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODEL_APP_STORE_MODULE_NAME)) store.unregisterModule(MODEL_APP_STORE_MODULE_NAME)
      if (store.hasModule(MANUFACTURER_APP_STORE_MODULE_NAME)) store.unregisterModule(MANUFACTURER_APP_STORE_MODULE_NAME)
    })

    if (router.currentRoute.params.id != undefined) {

      store.dispatch('app-model/fetchModel', {
        id: router.currentRoute.params.id
      })
          .then(response => {
            modelData.value = response.data
            searchQuery.value = response.data.assetManufacturerName
          })
          .catch(error => {
            if (error.response.status === 404) {
              //driverData.value = undefined
            }
          })
    } else {
      modelData.value = {
        id: '',
        name: '',
        assetManufacturerId: null,
        iconUrl: '',
      }
    }

    return {
      modelData,
      searchQuery,
      user
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style>
.title-head {
  margin-bottom: 32px;
}
</style>
