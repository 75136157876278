import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchModels(ctx, queryParams) {
            const { query, page, perPage, manufacturerId } = queryParams; 
            return new Promise((resolve, reject) => {
                axios
                    .get(`/api/v1/Asset/searchmodel?Search=${query}&ManufacturerId=${manufacturerId}&Page=${page}&PerPage=${perPage}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchModel(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                .get(`/api/v1/asset/getassetmodel?id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addModel(ctx, modelData) {
            return new Promise((resolve, reject) => {

                let model = modelData.modelData
                axios
                    .post('/api/v1/asset/createassetmodel', model)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        updateManufacturer(ctx, modelData) {
            return new Promise((resolve, reject) => {

                let model = modelData.modelData
                axios
                    .put('/api/v1/Asset/updateassetmodel', model)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}